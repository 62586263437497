import React, { useState } from 'react';
import { styled } from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../data/stores/alertStore';

interface CopyableTextProps {
	text: string;
	displayText?: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, displayText }) => {
	const dispatch = useDispatch();
	const [copied, setCopied] = useState(false);

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(text);
			setCopied(true);
			dispatch(
				showAlert({
					message: 'Copied to clipboard!',
					severity: 'success',
				})
			);
			setTimeout(() => setCopied(false), 2000);
		} catch (err) {
			dispatch(
				showAlert({
					message: 'Failed to copy to clipboard',
					severity: 'error',
				})
			);
		}
	};

	return (
		<Container>
			<TextSpan onClick={handleCopy}>{displayText || text}</TextSpan>
			<Tooltip title="Copy to clipboard">
				<IconButton
					onClick={handleCopy}
					size="small"
					color={copied ? 'success' : 'primary'}
					sx={{ padding: '2px' }}
				>
					<ContentCopyIcon fontSize="small" />
				</IconButton>
			</Tooltip>
		</Container>
	);
};

const Container = styled.span`
	display: inline-flex;
	align-items: center;
	gap: 4px;
	background-color: ${({ theme }) => theme.palette.action.hover};
	padding: 2px 6px;
	border-radius: 4px;
	margin: 0 2px;
`;

const TextSpan = styled.span`
	cursor: pointer;
	// color: ${({ theme }) => theme.palette.primary.main};
	font-size: 14px;
	font-weight: 500;
	&:hover {
		text-decoration: underline;
	}
`;

export default CopyableText;
