import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import SimpleTable from '../common/Table';
import Link from '../common/Link';
import GoogleDriveIcon from '../../images/google-drive.png';
import { styled } from 'styled-components';
import {
	fetchCompanyTemplates,
	fetchCustomTemplates,
} from '../../data/thunks/templateThunk';
import { getIconFromGoogleMimeType } from '../../utils/company-utils';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Button, IconButton, Tooltip } from '@mui/material';

import SearchBar from '../common/SearchBar';
import { useLocation } from 'react-router-dom';
import usePagination from '../../hooks/usePaginationStore';
import { updateTemplatesFilter } from '../../data/stores/templateStore';
import ToggleButton from '../common/buttons/ToggleButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from '../../data/stores/modalStore';
import { ProjectCategories } from '../../data/models/project';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function TemplatesTable(props) {
	const dispatch: AppDispatch = useDispatch();
	const location = useLocation();

	const { handleRowClick } = props;

	const {
		templates: { list, count, initialized },
		customTemplates: { list: customList, initialized: customInitialized },
		stage,
	} = useSelector((state: RootState) => state.templateState);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		setPageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination('templateState', 'templates', updateTemplatesFilter);

	const [customToggle, setCustomToggle] = useState('ALL');

	const isCustom = location.pathname.includes('custom');

	useEffect(() => {
		const filter = {
			where: { default: !isCustom },
		};
		dispatch(fetchCompanyTemplates(filter));
		dispatch(fetchCustomTemplates());
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'name',
			direction: 'ASC',
			page: 0,
			// take: pageSize,
			where: { default: !isCustom },
		};
		dispatch(fetchCompanyTemplates(filter));
	};

	const columns: GridColDef[] = useMemo(() => {
		const _columns = [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 350,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					const icon = getIconFromGoogleMimeType(params.row.mimeType);
					return (
						<NameContainer>
							<img
								style={{
									height: 20,
									marginRight: 10,
									objectFit: 'cover',
									cursor: 'pointer',
								}}
								src={icon.icon}
								alt={icon.alt}
							/>
							<Link onClick={() => handleRowClick(params.row)}>
								{params.value}
							</Link>
							{/* {isCustom && !params.row.verifiedTemplateExists && (
								<Tooltip title="The associated Google file does not exist">
									<IconButton
										sx={{
											marginLeft: 1,
										}}
										onClick={openGoogleFileNotFoundModal}
									>
										<ErrorOutlineOutlinedIcon
											color={'error'}
										/>
									</IconButton>
								</Tooltip>
							)} */}
						</NameContainer>
					);
				},
			},
			{
				field: 'projectCategory',
				headerName: 'Project Category',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				valueGetter: (params: any) => {
					return ProjectCategories[params]?.display || params;
				},
			},
			{
				field: 'updatedBy',
				headerName: 'Last modified',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					`${dayjs(
						params.row.updatedBy?.updated || params.row.updated
					).format('MMM D, YYYY')} ${
						params.row.updatedBy?.updated ? 'by' : ''
					} ${params.row.updatedBy?.name || ''}`,
			},
			{
				field: 'createdBy',
				headerName: 'Created By',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return params.row.createdBy?.name;
				},
			},
			{
				field: 'fileSize',
				headerName: 'File size',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					// Convert bytes to KB by dividing by 1024
					`${(params.value / 1024)?.toFixed(2)} KB`,
			},
			{
				field: 'links',
				headerName: '',
				minWidth: 240,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					const handleAdd = () => {
						dispatch(
							showModal({
								openId: 'addCustomTemplateModal',
								props: { googleTemplate: params.row },
							})
						);
					};
					const handleEdit = () => {
						dispatch(
							showModal({
								openId: 'editCustomTemplateModal',
								props: { companyTemplate: params.row },
							})
						);
					};
					// const handleDelete = () => {
					// 	dispatch(
					// 		showModal({
					// 			openId: 'deleteCustomTemplateModal',
					// 			props: { companyTemplate: params.row },
					// 		})
					// 	);
					// };
					const openGoogleFileNotFoundModal = () => {
						dispatch(
							showModal({
								openId: 'templateGoogleFileNotFoundModal',
								props: { companyTemplate: params.row },
							})
						);
					};

					return (
						<IconContainer>
							<img
								style={{
									height: 20,
									objectFit: 'cover',
									cursor: 'pointer',
								}}
								src={GoogleDriveIcon}
								alt={'Google Drive'}
								onClick={() => {
									window.open(
										`https://drive.google.com/file/d/${params.row.link}/view?usp=drivesdk`,
										'_blank'
									);
								}}
							/>
							{isCustom &&
								(params.row.activeVersion ? (
									<>
										{params.row.verifiedTemplateExists ? (
											<IconButton
												onClick={handleEdit}
												color={'primary'}
											>
												<EditIcon
													style={{ marginRight: 3 }}
												/>
											</IconButton>
										) : (
											<>
												<Tooltip title="The associated Google file does not exist">
													<IconButton
														sx={{
															marginLeft: 1,
														}}
														onClick={
															openGoogleFileNotFoundModal
														}
													>
														<ErrorOutlineOutlinedIcon
															color={'error'}
														/>
													</IconButton>
												</Tooltip>
												<ErrorText>
													Remove from Orbito
												</ErrorText>
											</>
										)}
										{/* <IconButton
											onClick={handleDelete}
											color={'error'}
										>
											<DeleteOutlineOutlinedIcon
												color={'warning'}
												fontSize={'medium'}
											/>
										</IconButton> */}
									</>
								) : (
									<AddToAstroButton onClick={handleAdd}>
										<AddIcon style={{ marginRight: 3 }} />{' '}
										Add to Orbito
									</AddToAstroButton>
								))}
						</IconContainer>
					);
				},
			},
		];
		return _columns;
	}, [handleRowClick, location.pathname]);

	const rows: any[] = useMemo(() => {
		const _rows: any[] = [];
		if (isCustom && customInitialized) {
			if (customToggle === 'ALL') {
				customList.forEach((template) => {
					if (
						// handle text search locally so we don't have to fetch from google again.
						searchText === '' ||
						template.name.toLowerCase().includes(searchText)
					) {
						_rows.push({
							...template,
							fileSize: template.fileSize || template.size,
							link:
								template.activeVersion?.googleId || template.id,
						});
					}
				});
			} else if (customToggle === 'NOT_ADDED') {
				customList.forEach((template) => {
					if (
						!template.activeVersion &&
						(searchText === '' ||
							template.name.toLowerCase().includes(searchText))
					) {
						_rows.push({
							...template,
							fileSize: template.fileSize || template.size,
							link:
								template.activeVersion?.googleId || template.id,
						});
					}
				});
			} else {
				customList.forEach((template) => {
					if (
						!!template.activeVersion &&
						(searchText === '' ||
							template.name.toLowerCase().includes(searchText))
					) {
						_rows.push({
							...template,
							fileSize: template.fileSize || template.size,
							link:
								template.activeVersion?.googleId || template.id,
						});
					}
				});
			}
		}
		if (!isCustom) {
			_rows.push(
				...list.map((template) => {
					return {
						...template,
						link: template.activeVersion.googleId,
					};
				})
			);
		}
		if (isCustom) {
			// sort rows first if they do not have an activeVersion and then by name
			_rows.sort((a, b) => {
				if (!a.activeVersion && b.activeVersion) {
					return -1;
				}
				if (a.activeVersion && !b.activeVersion) {
					return 1;
				}
				return a.name.localeCompare(b.name);
			});
		}

		return _rows;
	}, [list, customList, searchText, location.pathname, customToggle]);

	return (
		<>
			{/* {location.pathname.includes('custom') && !customInitialized && (
				<BusySpinner display={'FULL_SCREEN'} />
			)} */}
			<SimpleTable
				columns={columns}
				rows={rows}
				page={0}
				pageSize={50}
				rowCount={count}
				sortModel={[
					{
						field: 'created',
						sort: 'desc',
					},
				]}
				toolbar={
					<CustomToolbar
						searchText={searchText}
						customToggle={customToggle}
						setCustomToggle={setCustomToggle}
						handleTextSearch={handleTextSearch}
					/>
				}
				onSortModelChange={() => {}}
				searchText={searchText}
				// handleTextSearch={handleTextSearch}
				noRowsContent={
					<div>
						No custom templates have been added yet.
						<br />
						Add templates to the ORBITO - CUSTOM TEMPLATE in the
						Google Drive to view them here.
					</div>
				}
				onPaginationModelChange={() => {}}
				loading={
					!initialized ||
					stage === 'Busy' ||
					(location.pathname.includes('custom') && !customInitialized)
				}
			/>
		</>
	);
}

const NameContainer = styled.div`
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	gap: 15px;
`;

const ErrorText = styled.div`
	color: ${(props) => props.theme.palette.error.main};
	font-size: 14px;
	margin-left: -10px;
`;

const HeaderContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: space-between;
`;

const AddToAstroButton = styled(Button)`
	text-transform: none !important;
	font-weight: normal !important;
`;

interface TableToolbarProps {
	// title?: string;
	searchText?: string;
	handleTextSearch?: (localSearchText: string) => void;
	handleAdd?: () => void;
	customToggle: string;
	setCustomToggle: (value: string) => void;
	// filterOptions?: TableFilterOption[];
}

const CustomToolbar: React.FC<TableToolbarProps> = (
	props: TableToolbarProps
) => {
	const {
		searchText = '',
		handleTextSearch,
		handleAdd,
		customToggle,
		setCustomToggle,
	} = props;

	const location = useLocation();

	const [localSearchText, setLocalSearchText] = useState(searchText);

	const handleSearch = () => {
		handleTextSearch && handleTextSearch(localSearchText);
	};

	const handleClear = () => {
		setLocalSearchText('');
		handleTextSearch && handleTextSearch('');
	};

	return (
		<StyledGridToolbarContainer>
			<HeaderContainer>
				<HeaderLeftContainer>
					<Box
						sx={{
							width: 250,
						}}
					>
						<SearchBar
							searchText={localSearchText}
							setSearchText={setLocalSearchText}
							handleTextSearch={handleSearch}
							handleClear={handleClear}
						/>
					</Box>
					{location.pathname.includes('custom') && (
						<ToggleButtonContainer>
							<ToggleButton
								value={customToggle}
								onChange={(value) => setCustomToggle(value)}
								options={[
									{
										value: 'ALL',
										display: 'ALL',
									},
									{
										value: 'ADDED',
										display: 'ADDED',
									},
									{
										value: 'NOT_ADDED',
										display: 'NOT ADDED',
									},
								]}
							/>
						</ToggleButtonContainer>
					)}
				</HeaderLeftContainer>
			</HeaderContainer>
		</StyledGridToolbarContainer>
	);
};

export const TableHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;

const ToggleButtonContainer = styled.div`
	margin-left: 10px;
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
	display: block !important;
	padding: 10px 10px 0 10px !important;
`;

const HeaderLeftContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-start;
`;

const HeaderRightContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`;
