import { Paper as MuiPaper } from '@mui/material';
import { styled } from 'styled-components';
import { RootState } from '../../../data/store';
import { useSelector } from 'react-redux';

interface PaperProps {
	variant?: 'elevation' | 'outlined';
	style?: any;
	children: React.ReactNode;
	className?: string;
	border?: boolean;
	gradientBorder?: boolean;
}
const Paper = (props: PaperProps) => {
	const {
		children,
		variant = 'outlined',
		style = {},
		className,
		border,
		gradientBorder,
	} = props;

	const themePreference = useSelector(
		(state: RootState) => state.authState.user?.preferences?.theme
	);

	return (
		<MuiPaper
			sx={(theme) => {
				return {
					borderColor: border ? theme.palette.divider : 'transparent',
					fontSize: '0.875rem',
					position: 'relative',
					borderRadius: 2,
					overflow: 'hidden',
					...(gradientBorder &&
						// @ts-ignore
						themePreference === 'orbito' && {
							padding: '1px',
							background: `linear-gradient(to right, #4285F4 0%, #EA4335 30%, #FBBC04 60%, #4285F4 100%)`,
							backgroundSize: '200% 100%',
							'& > *': {
								background: theme.palette.background.paper,
								borderRadius: 1.5,
								height: '100%',
								width: '100%',
							},
						}),
				};
			}}
			style={{ ...style }}
			variant={variant}
			className={className}
		>
			{gradientBorder ? (
				<div style={{ padding: 'inherit' }}>{children}</div>
			) : (
				children
			)}
		</MuiPaper>
	);
};

export default Paper;

export const PaperHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;

export const PaperHeaderNav = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
