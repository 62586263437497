import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';
import { Box } from '../layout/Content';

export interface CheckboxItem {
	id: any;
	name: any;
	checked: boolean;
	disabled?: boolean;
	isActive?: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ParentCheckboxItem extends CheckboxItem {
	indeterminate?: boolean;
	children?: CheckboxItem[];
}

interface CheckboxProps {
	items?: ParentCheckboxItem[];
	size?: 'small' | 'medium';
}

export default function Checkbox({
	items,
	size = 'medium',
}: CheckboxProps): JSX.Element {
	if (!items?.length) return <></>;

	return (
		<FormGroup>
			{items.map((item) => {
				if (item.children) {
					return (
						<div key={item.id}>
							<FormControlLabel
								label={item.name}
								control={
									<MUICheckbox
										checked={item.checked}
										indeterminate={item.indeterminate}
										onChange={item.handleOnChange}
										disabled={item.disabled}
									/>
								}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									ml: 3,
								}}
							>
								{item.children.map((child) => (
									<FormControlLabel
										key={child.id}
										sx={{ width: 'auto' }}
										control={
											<MUICheckbox
												checked={child.checked}
												onChange={child.handleOnChange}
												disabled={child.disabled}
											/>
										}
										label={child.name}
									/>
								))}
							</Box>
						</div>
					);
				}

				return (
					<Box
						key={item.id}
						sx={{
							position: 'relative',
							'&::before': {
								content: '""',
								position: 'absolute',
								left: '-24px',
								top: 0,
								bottom: 0,
								width: '4px',
								borderRadius: '4px',
								backgroundColor: item.isActive
									? 'primary.main'
									: 'transparent',
								transition: 'background-color 0.2s ease',
							},
						}}
					>
						<FormControlLabel
							label={item.name}
							control={
								<MUICheckbox
									checked={item.checked}
									onChange={item.handleOnChange}
									disabled={item.disabled}
									size={size}
								/>
							}
						/>
					</Box>
				);
			})}
		</FormGroup>
	);
}
