import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import Modal from './common/Modal';
import { styled } from 'styled-components';
import Button from './common/buttons/Button';
import {
	updateCompany,
	verifyGoogleDriveSetup,
} from '../services/company-service';
import { closeModal } from '../data/stores/modalStore';
import Link from './common/Link';
import CopyableText from './common/CopyableText';
import { CircularProgress } from '@mui/material';
import Checkbox from './common/form/Checkbox';
import VerifyGraphic1 from '../images/Verify-Graphic-1.svg';
import VerifyGraphic2 from '../images/Verify-Graphic-2.svg';
import VerifyGraphic3 from '../images/Verify-Graphic-3.svg';
import VerifyGraphic4 from '../images/Verify-Graphic-4.svg';
import VerifyGraphic5 from '../images/Verify-Graphic-5.svg';
import VerifyGraphic6 from '../images/Verify-Graphic-6.svg';
import { showAlert } from '../data/stores/alertStore';

interface GoogleDriveSetupResponse {
	[key: string]: {
		exists: boolean;
		message: string;
	};
}

interface StepItem {
	id: number;
	errorId?: string;
	name: React.ReactNode;
	checked: boolean;
	disabled: boolean;
	isActive: boolean;
	handleOnChange: () => void;
	img: string;
}

export default function GoogleWorkspaceVerificationModal() {
	const dispatch = useDispatch<AppDispatch>();
	const { openId } = useSelector((state: RootState) => state.modalState);

	const {
		company: { googleDriveSetupVerified },
	} = useSelector((state: RootState) => state.companyState);

	const [googleDriveSetupResponse, setGoogleDriveSetupResponse] =
		useState<GoogleDriveSetupResponse | null>(null);
	const [successModalOpen, setSuccessModalOpen] = useState(false);
	const [ignore, setIgnore] = useState(false);
	const [busy, setBusy] = useState(false);

	const createStepItems: () => StepItem[] = () => [
		{
			id: 1,
			name: (
				<StepText>
					Log in to your associated Google Workspace account at{' '}
					<Link
						onClick={() =>
							window.open(
								'https://workspace.google.com',
								'_blank'
							)
						}
					>
						workspace.google.com.
					</Link>
				</StepText>
			),
			checked: false,
			disabled: false,
			isActive: true,
			handleOnChange: () => handleStepComplete(1),
			img: VerifyGraphic1,
		},
		{
			id: 2,
			errorId: 'currentProjectsDrive',
			name: (
				<StepText>
					Verify that a google drive exists named{' '}
					<CopyableText text="ORBITO - CURRENT PROJECTS" />.
				</StepText>
			),
			checked: false,
			disabled: true,
			isActive: false,
			handleOnChange: () => handleStepComplete(2),
			img: VerifyGraphic2,
		},
		{
			id: 3,
			errorId: 'currentProjectsDriveServiceAccount',
			name: (
				<StepText>
					Verify that you have added the service worker account named{' '}
					<CopyableText text="service-account-test-1@astrodemo-416223.iam.gserviceaccount.com" />{' '}
					to the "ORBITO - CURRENT PROJECTS" drive.
				</StepText>
			),
			checked: false,
			disabled: true,
			isActive: false,
			handleOnChange: () => handleStepComplete(3),
			img: VerifyGraphic3,
		},
		{
			id: 4,
			errorId: 'generalDrive',
			name: (
				<StepText>
					Verify that a google drive exists named{' '}
					<CopyableText text="ORBITO - GENERAL" />.
				</StepText>
			),
			checked: false,
			disabled: true,
			isActive: false,
			handleOnChange: () => handleStepComplete(4),
			img: VerifyGraphic4,
		},
		{
			id: 5,
			errorId: 'generalDriveServiceAccount',
			name: (
				<StepText>
					Verify that you have added the service worker account named{' '}
					<CopyableText text="service-account-test-1@astrodemo-416223.iam.gserviceaccount.com" />{' '}
					to the "ORBITO - GENERAL" drive.
				</StepText>
			),
			checked: false,
			disabled: true,
			isActive: false,
			handleOnChange: () => handleStepComplete(5),
			img: VerifyGraphic5,
		},
		{
			id: 6,
			errorId: 'customTemplatesDrive',
			name: (
				<StepText>
					Verify that a folder exists in the "ORBITO - GENERAL" drive
					named <CopyableText text="ORBITO - CUSTOM TEMPLATES" />.
				</StepText>
			),
			checked: false,
			disabled: true,
			isActive: false,
			handleOnChange: () => handleStepComplete(6),
			img: VerifyGraphic6,
		},
	];

	const [steps, setSteps] = useState<StepItem[]>(createStepItems());

	const handleVerify = async () => {
		setBusy(true);
		const response = await verifyGoogleDriveSetup();
		setGoogleDriveSetupResponse(response);
		setBusy(false);
	};

	const handleStepComplete = (stepId: number) => {
		setSteps((prevSteps) => {
			// Find the last completed step
			const lastCompletedStepId = Math.max(
				...prevSteps
					.filter((step) => step.checked)
					.map((step) => step.id),
				0
			);

			// If trying to uncheck a step
			if (prevSteps.find((step) => step.id === stepId)?.checked) {
				// Only allow unchecking if it's the last completed step
				if (stepId === lastCompletedStepId) {
					return prevSteps.map((step) => ({
						...step,
						checked: step.id === stepId ? false : step.checked,
						disabled: step.id > stepId,
						isActive:
							step.id === stepId ? false : step.id === stepId - 1,
					}));
				}
				return prevSteps;
			}

			// For checking steps - maintain original behavior
			return prevSteps.map((step) => {
				if (step.id === stepId) {
					return { ...step, checked: true, isActive: false };
				}
				if (step.id === stepId + 1) {
					return { ...step, disabled: false, isActive: true };
				}
				return { ...step, isActive: false };
			});
		});

		if (steps.every((step) => step.checked)) {
			console.log('done');
		}
	};

	useEffect(() => {
		if (googleDriveSetupResponse) {
			setSteps((prevSteps) => {
				return prevSteps.map((step) => {
					const matchingError = Object.entries(
						googleDriveSetupResponse
					).find(([key]) => key === step.errorId);

					// Extract the original step name without any error message
					const originalName =
						React.isValidElement(step.name) &&
						step.name.type === React.Fragment
							? step.name.props.children[0]
							: step.name;

					if (matchingError) {
						// If there's a matching error response
						if (!matchingError[1].exists) {
							// If the error exists, show the error message
							return {
								...step,
								name: (
									<>
										{originalName}
										<ErrorText>
											{matchingError[1].message}
										</ErrorText>
									</>
								),
							};
						} else {
							// If the error is resolved, show only the original name
							return {
								...step,
								name: originalName,
							};
						}
					}
					return {
						...step,
						name: originalName,
					};
				});
			});
		}
	}, [googleDriveSetupResponse]);

	const errorMessages = useMemo(() => {
		const errors: string[] = [];
		if (googleDriveSetupResponse) {
			Object.entries(googleDriveSetupResponse).forEach(
				([key, item]: [string, any]) => {
					if (!item.exists) {
						errors.push(item.message);
					}
				}
			);
			if (errors.length === 0) {
				updateCompany({
					googleDriveSetupVerified: true,
					googleDriveSetupVerifiedDate: new Date(),
				});
				setSuccessModalOpen(true);
			} else {
				dispatch(
					showAlert({
						message: 'Error connecting to Google Drive.',
						severity: 'error',
					})
				);
			}
		}
		return errors;
	}, [googleDriveSetupResponse]);

	const handleClose = () => {
		dispatch(closeModal());
		setSuccessModalOpen(false);
	};

	const open =
		(openId === 'googleWorkspaceVerificationModal' ||
			errorMessages.length > 0) &&
		!ignore;

	const currentStep = steps.find((step) => !step.checked);

	return (
		<>
			<Modal
				open={open}
				handleClose={handleClose}
				sx={{ padding: 0, minWidth: '1200px', overflow: 'hidden' }}
				disableBackdropClose={true}
			>
				<Container>
					<LeftContainer>
						{googleDriveSetupVerified ? (
							<WelcomeText>
								We have noticed that your google drive is not
								correctly set up for your user. Follow the steps
								below and select 'VERIFIED' when completed.
								Please note, the drives and folders could exist
								in your google drive, but your user might not
								have access to them. To assure that your user
								can create projects, you will need to make sure
								to verify the drives exists and that your user
								has access to them.
							</WelcomeText>
						) : (
							<>
								<WelcomeHeader>
									Connect to Google Drive
								</WelcomeHeader>
								<WelcomeText>
									Before you jump in, we first need to verify
									that your Google Dive is correctly set up to
									receive your projects from Orbito. Please
									follow the steps below to share your Google
									Drive with Orbito and select 'VERIFIED' when
									completed.
								</WelcomeText>
							</>
						)}
						<StepsList>
							<CustomCheckboxGroup>
								<Checkbox items={steps} />
							</CustomCheckboxGroup>
						</StepsList>
						{/* {errorMessages.length > 0 && (
							<ErrorContainer>
								<p>We could not verify the following:</p>
								<ul>
									{errorMessages.map((error) => (
										<li key={error}>{error}</li>
									))}
								</ul>
							</ErrorContainer>
						)} */}
						<Button
							onClick={handleVerify}
							variant="contained"
							color="primary"
							disabled={
								busy || !steps.every((step) => step.checked)
							}
							style={{ textTransform: 'uppercase' }}
						>
							Verify
							{busy && (
								<CircularProgress size={24} sx={{ ml: 1 }} />
							)}
						</Button>
					</LeftContainer>
					<RightContainer>
						<img
							// @ts-ignore
							src={currentStep?.img || steps[5].img}
							style={{
								height: '100%',
								borderTopRightRadius: 16,
								borderBottomRightRadius: 16,
							}}
						/>
					</RightContainer>
				</Container>
			</Modal>
			<SuccessModal open={successModalOpen} handleClose={handleClose} />
		</>
	);
}

const SuccessModal = ({
	open,
	handleClose,
}: {
	open: boolean;
	handleClose: () => void;
}) => {
	return (
		<Modal
			open={open}
			handleClose={handleClose}
			disableBackdropClose={true}
		>
			<FormContainer>
				<WelcomeHeader>Success!</WelcomeHeader>
				<WelcomeText>
					Your Google Drive is correctly set up to receive and
					automate your projects from Orbito.
				</WelcomeText>
				<Button
					onClick={handleClose}
					variant="contained"
					color="primary"
					style={{ textTransform: 'uppercase' }}
				>
					Continue to Orbito
				</Button>
			</FormContainer>
		</Modal>
	);
};

const ErrorContainer = styled.div`
	max-width: 600px;
	padding: 24px;
	border-radius: 8px;
	color: ${({ theme }) => theme.palette.error.main};
`;

const FormContainer = styled.div`
	padding: 24px;
	overflow-y: auto;
	max-height: 80vh;
`;

const LeftContainer = styled.div`
	flex: 1;
	padding: 24px;
	overflow-y: auto;
	max-height: 80vh;
`;

const RightContainer = styled.div`
	min-width: 300px;
	height: 80vh;
	display: flex;
	align-items: stretch;

	img {
		object-fit: cover;
		width: 100%;
	}
`;

const Container = styled.div`
	min-width: 800px;
	max-height: 80vh;
	display: flex;
	overflow: hidden;
`;

const WelcomeHeader = styled.h2`
	font-size: 32px;
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.text.secondary};
	font-weight: 400;
`;

const WelcomeText = styled.p`
	font-size: 16px;
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.text.primary};
	line-height: 1.5;
`;

const StepText = styled.div`
	line-height: 1.5;
	margin-top: 0;
`;

const CustomCheckboxGroup = styled.div`
	.MuiFormGroup-root {
		gap: 16px;
	}
`;

const StepsList = styled.div`
	margin-bottom: 32px;
`;

const ErrorText = styled.div`
	color: ${({ theme }) => theme.palette.error.main};
	font-size: 14px;
	margin-top: 8px;
	line-height: 1.4;
`;
