import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../common/Modal';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import ModalHeader from '../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { closeModal } from '../../data/stores/modalStore';
import Input from '../common/form/Input';
import RadioButtons from '../common/buttons/RadioButtons';
import {
	// createCompanyTemplate,
	editCompanyTemplate,
} from '../../data/thunks/templateThunk';
import Button from '../common/buttons/Button';

export default function EditCustomTemplateModal() {
	const {
		openId,
		props: { companyTemplate },
	} = useSelector((state: RootState) => state.modalState);

	const open = openId === 'editCustomTemplateModal';

	const dispatch = useDispatch<AppDispatch>();

	const [description, setDescription] = useState(
		companyTemplate?.description || ''
	);
	const [projectCategory, setProjectCategory] = useState(
		companyTemplate?.projectCategory || ''
	);

	useEffect(() => {
		setDescription(companyTemplate?.description || '');
		setProjectCategory(companyTemplate?.projectCategory || '');
	}, [companyTemplate]);

	const handleClose = () => {
		dispatch(closeModal());
	};

	const onSave = () => {
		if (companyTemplate) {
			dispatch(
				editCompanyTemplate({
					id: companyTemplate.id,
					description,
					projectCategory,
				})
			);
		}
		handleClose();
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<Container>
				<ModalHeader>Edit Custom Template</ModalHeader>
				<Input
					label={'Name'}
					value={companyTemplate?.name}
					id="name"
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					disabled
					style={{ marginBottom: 20 }}
					// helperText={errors.name?.message}
				/>
				<Input
					label={'Description'}
					id="description"
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					multiline
					rows={3}
					maxRows={3}
					disabled={!companyTemplate?.verifiedTemplateExists}
					// helperText={errors.name?.message}
				/>
				<ProjectCategoryContainer>
					<RadioButtons
						label="Project Category"
						name="projectCategory"
						options={[
							{ value: 'CREATIVE', label: 'Creative' },
							{
								value: 'LEGAL_AND_CONTRACT',
								label: 'Legal and Contract',
							},
							{
								value: 'PRODUCTION',
								label: 'Production',
							},
							{
								value: 'POST_PRODUCTION',
								label: 'Post-Production',
							},
						]}
						onChange={(e) => {
							setProjectCategory(e.target.value);
						}}
						value={projectCategory}
						disabled={!companyTemplate?.verifiedTemplateExists}
					/>
				</ProjectCategoryContainer>
				{!companyTemplate?.verifiedTemplateExists && (
					<ErrorText>
						The associated Google file does not exist within the
						"ORBITO - CUSTOM TEMPLATES" folder. The custom template
						will fail to create during new project creation.
					</ErrorText>
				)}
				<SaveCancelContainer>
					{companyTemplate?.verifiedTemplateExists ? (
						<SaveCancelButtons
							handleSave={onSave}
							handleCancel={handleClose}
							saveDisabled={!description || !projectCategory}
						/>
					) : (
						<Button
							onClick={() => {}}
							color="error"
							variant="outlined"
						>
							Delete
						</Button>
					)}
				</SaveCancelContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	min-width: 400px;
`;

const ProjectCategoryContainer = styled.div`
	margin-top: 20px;
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;

const ErrorText = styled.div`
	color: ${(props) => props.theme.palette.error.main};
	margin-bottom: 20px;
	margin-top: 20px;
	font-weight: 600;
`;
