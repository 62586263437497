import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridSortModel, GridColDef } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import TableToolbar from './TableToolbar';
import { styled } from 'styled-components';

interface TableProps {
	title?: string;
	columns: GridColDef[];
	rows: any[];
	rowCount: number;
	loading?: boolean;
	// processRowUpdate?: (row: any) => void;
	initialState?: any;
	sortModel: GridSortModel;
	onSortModelChange: (model: GridSortModel) => void;
	page: number;
	pageSize: number;
	onPaginationModelChange: (paginationModel: any) => void;
	handleTextSearch?: (searchText: string) => void;
	searchText?: string;
	// setSearchText?: (searchText: string) => void;
	checkboxSelection?: boolean;
	rowHeight?: number;
	pagination?: true;
	paginationMode?: 'client' | 'server';
	toolbar?: any;
	hideFooterPagination?: boolean;
	boxSx?: any;
	autoHeight?: boolean;
	hideFooter?: boolean;
	handleAdd?: () => void;
	filterOptions?: TableFilterOption[];
	noRowsContent?: React.ReactNode;
}

export interface TableFilterOption {
	field: string;
	label: string;
	value: string;
	options: any[];
	onChange: any;
}

const StyledNoRowsOverlay = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: '25px',
	height: '100%',
	padding: '2rem',
	fontSize: '16px',
	fontWeight: 500,
});

const StyledNoRowsOverlayText = styled('div')({
	textAlign: 'center',
	maxWidth: '600px',
});

function NoRowsOverlay({ content }: { content?: React.ReactNode }) {
	return (
		<StyledNoRowsOverlay>
			<StyledNoRowsOverlayText>
				{content || 'No rows'}
			</StyledNoRowsOverlayText>
		</StyledNoRowsOverlay>
	);
}

export default function Table(props: TableProps) {
	const {
		title,
		columns,
		rows,
		rowCount,
		loading = false,
		// processRowUpdate,
		initialState,
		sortModel,
		onSortModelChange,
		page,
		pageSize,
		onPaginationModelChange,
		checkboxSelection,
		rowHeight = 52,
		pagination,
		paginationMode = 'server',
		handleTextSearch,
		searchText,
		hideFooterPagination,
		toolbar,
		boxSx = {},
		autoHeight = false,
		hideFooter = false,
		handleAdd,
		filterOptions = [],
		noRowsContent,
	} = props;

	const theme = useTheme();

	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: theme.palette.background.paper,
				borderRadius: 2,
				position: 'relative',
				flex: 1,
				overflow: 'auto',
				height: 'calc(100vh - 98px)',
				...boxSx,
			}}
		>
			<DataGrid
				sx={{
					'& .MuiDataGrid-columnHeader': {
						backgroundColor: theme.palette.background.paper,
					},
					'& .MuiDataGrid-filler': {
						backgroundColor: theme.palette.background.paper,
					},
					borderColor: 'transparent',
					borderRadius: 2,
				}}
				loading={loading}
				rows={rows}
				rowCount={rowCount}
				hideFooterPagination={hideFooterPagination}
				paginationMode={paginationMode}
				slots={{
					toolbar: () =>
						toolbar ? (
							toolbar
						) : (
							<TableToolbar
								title={title}
								searchText={searchText}
								handleTextSearch={handleTextSearch}
								handleAdd={handleAdd}
								filterOptions={filterOptions}
							/>
						),
					noRowsOverlay: () => (
						<NoRowsOverlay content={noRowsContent} />
					),
				}}
				pagination={pagination}
				paginationModel={{ page, pageSize }}
				onPaginationModelChange={(paginationModel) => {
					onPaginationModelChange(paginationModel);
				}}
				pageSizeOptions={[]}
				columns={columns}
				sortModel={sortModel}
				keepNonExistentRowsSelected
				onSortModelChange={onSortModelChange}
				checkboxSelection={checkboxSelection}
				// editMode="row"
				initialState={initialState}
				// onSelectionModelChange={(newSelectionModel) => {
				// 	setSelectionModel(newSelectionModel);
				// }}
				// selectionModel={selectionModel}
				hideFooterSelectedRowCount
				rowHeight={rowHeight}
				autoHeight={autoHeight}
				hideFooter={hideFooter}
			/>
		</Box>
	);
}

export function SimpleTable(props: TableProps) {
	const {
		title,
		columns,
		rows,
		rowCount,
		loading = false,
		// processRowUpdate,
		initialState,
		sortModel,
		onSortModelChange,
		page,
		pageSize,
		onPaginationModelChange,
		checkboxSelection,
		rowHeight = 52,
		pagination,
		paginationMode = 'server',
		handleTextSearch,
		searchText,
		hideFooterPagination,
		toolbar,
		boxSx = {},
		autoHeight = false,
		hideFooter = false,
		handleAdd,
		filterOptions = [],
		noRowsContent,
	} = props;

	const theme = useTheme();

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-columnHeader': {
					backgroundColor: theme.palette.background.paper,
				},
				'& .MuiDataGrid-filler': {
					backgroundColor: theme.palette.background.paper,
				},
				borderColor: 'transparent',
				borderRadius: 2,
			}}
			loading={loading}
			rows={rows}
			rowCount={rowCount}
			hideFooterPagination={hideFooterPagination}
			paginationMode={paginationMode}
			slots={{
				toolbar: () =>
					toolbar ? (
						toolbar
					) : (
						<TableToolbar
							title={title}
							searchText={searchText}
							handleTextSearch={handleTextSearch}
							handleAdd={handleAdd}
							filterOptions={filterOptions}
						/>
					),
				noRowsOverlay: () => <NoRowsOverlay content={noRowsContent} />,
			}}
			pagination={pagination}
			paginationModel={{ page, pageSize }}
			onPaginationModelChange={(paginationModel) => {
				onPaginationModelChange(paginationModel);
			}}
			pageSizeOptions={[]}
			columns={columns}
			sortModel={sortModel}
			keepNonExistentRowsSelected
			onSortModelChange={onSortModelChange}
			checkboxSelection={checkboxSelection}
			initialState={initialState}
			hideFooterSelectedRowCount
			rowHeight={rowHeight}
			autoHeight={autoHeight}
			hideFooter={hideFooter}
		/>
	);
}

export const TableHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;
