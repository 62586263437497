import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import Table from '../common/Table';
import { GridToolbarContainer } from '@mui/x-data-grid';
import ProjectStatusDisplay from '../projects/ProjectStatusDisplay';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { useNavigate } from 'react-router-dom';
import {
	CompanyUpdateProjectTypesDTO,
	ProjectType,
} from '../../data/models/company';
import { Button, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { updateCompanyProjectTypes } from '../../services/company-service';
import { useDispatch } from 'react-redux';
import {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshCompanySuccess,
} from '../../data/stores/companyStore';
import { showAlert } from '../../data/stores/alertStore';
import ProjectCategoryCreationModal from './ProjectTypeCreationModal';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';

export default function CompanyProjectTypes({
	projectTypes,
	initialized,
}: {
	projectTypes: ProjectType[];
	initialized: boolean;
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [sortModel, setSortModel] = useState([
		{ field: 'display', sort: 'asc' },
	]);

	const handleArchive = async (id: string) => {
		dispatch(asyncCallStart());
		try {
			const data: CompanyUpdateProjectTypesDTO = {
				id,
				active: false,
			};
			const response = await updateCompanyProjectTypes([data]);
			dispatch(refreshCompanySuccess(response));
			dispatch(asyncCallDone());
			dispatch(
				showAlert({
					message: 'Project type archived',
					severity: 'success',
				})
			);
		} catch (error) {
			console.log('error', error);
			dispatch(asyncCallError(error));
		}
	};

	const handleUnarchive = async (id: string) => {
		dispatch(asyncCallStart());
		try {
			const data: CompanyUpdateProjectTypesDTO = {
				id,
				active: true,
			};
			const response = await updateCompanyProjectTypes([data]);
			dispatch(refreshCompanySuccess(response));
			dispatch(asyncCallDone());
			dispatch(
				showAlert({
					message: 'Project type unarchived',
					severity: 'success',
				})
			);
		} catch (error) {
			console.log('error', error);
			dispatch(asyncCallError(error));
		}
	};

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'display',
				headerName: 'Type',
				minWidth: 325,
				disableColumnMenu: true,
				sortable: true,
				renderCell: (params: any) => {
					return (
						<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
							{params.value}
						</div>
					);
				},
			},
			{
				field: 'active',
				headerName: 'Active',
				minWidth: 150,
				disableColumnMenu: true,
				sortable: true,
				renderCell: (params: any) => (
					<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
						<ProjectStatusDisplay
							status={!!params.value ? 'ACTIVE' : 'ARCHIVED'}
						/>
					</div>
				),
			},
			{
				field: 'createdBy',
				headerName: 'Created By',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: true,
				renderCell: (params: any) => (
					<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
						{params.value?.name}
					</div>
				),
			},
			{
				field: 'created',
				headerName: 'Created On',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: true,
				renderCell: (params: any) => (
					<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
						{dayjs(params.value).format('MMM D, h:mm A')}
					</div>
				),
			},
			{
				field: 'actions',
				headerName: '',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<div>
							{params.row.createdBy.id != 0 &&
								(params.row.active ? (
									<IconButton
										onClick={() =>
											handleArchive(params.row.id)
										}
									>
										<DeleteOutlineOutlinedIcon
											color={'warning'}
										/>
									</IconButton>
								) : (
									<IconButton
										onClick={() =>
											handleUnarchive(params.row.id)
										}
									>
										<UnarchiveOutlinedIcon
											color={'disabled'}
										/>
									</IconButton>
								))}
						</div>
					);
				},
			},
		];
	}, [projectTypes]);

	const rows: any[] = useMemo(() => {
		const sortedProjectTypes = [...projectTypes].sort((a, b) => {
			const sortItem = sortModel[0];
			if (!sortItem) return 0;

			const aValue =
				sortItem.field === 'createdBy'
					? a[sortItem.field]?.name
					: a[sortItem.field];
			const bValue =
				sortItem.field === 'createdBy'
					? b[sortItem.field]?.name
					: b[sortItem.field];

			if (sortItem.sort === 'asc') {
				return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
			} else {
				return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
			}
		});

		return sortedProjectTypes.map((projectType) => ({
			id: projectType.id,
			display: projectType.display,
			active: projectType.active,
			createdBy: projectType.createdBy,
			created: projectType.created,
		}));
	}, [projectTypes, sortModel]);

	return (
		<Paper gradientBorder>
			<Table
				columns={columns}
				rows={rows}
				page={0}
				pageSize={100}
				rowCount={projectTypes.length}
				sortModel={sortModel as GridSortModel}
				onSortModelChange={(model) => setSortModel(model as any)}
				onPaginationModelChange={() => {}}
				// hideFooterPagination
				toolbar={<Toolbar />}
				loading={!initialized}
			/>
		</Paper>
	);
}

const Toolbar = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<StyledGridToolbarContainer>
			<PaperHeader>Project Types</PaperHeader>
			<HelpText>
				Add or disable project types to categorize your templates and
				projects.
			</HelpText>
			<AddToAstroButton onClick={() => setIsModalOpen(true)}>
				<AddIcon style={{ marginRight: 3 }} /> Add Project Type
			</AddToAstroButton>
			<ProjectCategoryCreationModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</StyledGridToolbarContainer>
	);
};

const AddToAstroButton = styled(Button)`
	text-transform: none !important;
	font-weight: normal !important;
	margin-top: 15px !important;
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
	display: block !important;
	padding: 10px 10px 0 10px !important;
`;

const HelpText = styled.div`
	font-size: 14px;
	color: ${(props) => props.theme.palette.text.secondary};
	margin-top: 15px;
	padding-left: 10px;
`;
