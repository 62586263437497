import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import Modal from './common/Modal';
import { styled } from 'styled-components';
import Button from './common/buttons/Button';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Config from '../config';
import Link from './common/Link';
import { updateSelf } from '../data/thunks/userThunk';
import Checkbox from './common/form/Checkbox';

export default function SubscriptionModal() {
	const {
		company: { subscription },
	} = useSelector((state: RootState) => state.companyState);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
		useState(false);

	const isTrialExpired = subscription
		? subscription.status === 'trialing' &&
		  dayjs(subscription.trialEnd).isBefore(dayjs())
		: false;

	const open =
		(subscription?.status === 'canceled' ||
			subscription?.status === 'incomplete' ||
			isTrialExpired) &&
		location.pathname !== '/terms-and-conditions' &&
		location.pathname !== '/company' &&
		location.pathname !== '/signup-company';

	const handleContinue = () => {
		dispatch(
			updateSelf({
				acceptedTermsAndConditions: true,
				acceptedTermsAndConditionsDate: new Date(),
			})
		);
		navigate('/company', { state: { openSubscriptionModal: true } });
	};

	return (
		<Modal open={open} handleClose={() => {}}>
			<FormContainer>
				{subscription?.status === 'canceled' && (
					<SubscriptionCanceled />
				)}
				{isTrialExpired && (
					<TrialEnded
						freeTrialEnd={subscription?.trialEnd || new Date()}
					/>
				)}
				{subscription?.status === 'incomplete' && (
					<SubscriptionIncomplete
						acceptedTermsAndConditions={acceptedTermsAndConditions}
						setAcceptedTermsAndConditions={
							setAcceptedTermsAndConditions
						}
					/>
				)}
				<Button
					onClick={handleContinue}
					variant="contained"
					color="primary"
					disabled={!acceptedTermsAndConditions}
				>
					Continue to payment
				</Button>
			</FormContainer>
		</Modal>
	);
}

const SubscriptionIncomplete = (props: {
	acceptedTermsAndConditions: boolean;
	setAcceptedTermsAndConditions: (accepted: boolean) => void;
}) => {
	return (
		<WelcomeContainer>
			<WelcomeHeader>You're almost done!</WelcomeHeader>
			<WelcomeText>
				Review our Terms of Service and confirm your subscription. To
				begin your 30-day free trial, you will be asked to add a payment
				method. You can cancel anytime during the trial period.
			</WelcomeText>
			<Link
				onClick={() =>
					window.open(
						`${Config.baseUrl}/terms-and-conditions`,
						'_blank'
					)
				}
			>
				Terms of Service
			</Link>
			<Checkbox
				size={'small'}
				// onClick={(e) => e.stopPropagation()}
				items={[
					{
						id: 'acceptedTermsAndConditions',
						name: 'I accept the Terms of Service',
						checked: props.acceptedTermsAndConditions,
						handleOnChange: () =>
							props.setAcceptedTermsAndConditions(
								!props.acceptedTermsAndConditions
							),
					},
				]}
			/>
		</WelcomeContainer>
	);
};

const SubscriptionCanceled = () => {
	return (
		<MessageContainer>
			<Typography>Your subscription has been canceled.</Typography>
		</MessageContainer>
	);
};

const TrialEnded = (props: { freeTrialEnd: Date }) => {
	return (
		<MessageContainer>
			<h2>Free Trial Expired</h2>
			<p>
				Your free trial period has ended. To continue using our
				services, please upgrade to a paid subscription.
			</p>
			<p>
				Your trial ended on:{' '}
				{dayjs(props.freeTrialEnd).format('MMMM D, YYYY')}
			</p>
		</MessageContainer>
	);
};

const MessageContainer = styled.div`
	margin-bottom: 20px;
	text-align: center;

	h2 {
		color: ${({ theme }) => theme.palette.error.main};
		margin-bottom: 16px;
	}

	p {
		margin-bottom: 8px;
	}
`;

const WelcomeContainer = styled.div`
	margin-bottom: 20px;

	h2 {
		margin-bottom: 16px;
	}

	p {
		margin-bottom: 8px;
	}
`;

const FormContainer = styled.div`
	max-width: 600px;
`;

const WelcomeHeader = styled.h2`
	font-size: 32px;
	margin-bottom: 16px;
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: 400;
`;

const WelcomeText = styled.p`
	font-size: 16px;
	margin-bottom: 16px;
	font-weight: 400;
`;
