import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { SimpleTable } from '../common/Table';
import { styled } from 'styled-components';
import { fetchTemplateMappings } from '../../data/thunks/templateThunk';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Button, IconButton, Tooltip } from '@mui/material';

import SearchBar from '../common/SearchBar';
import usePagination from '../../hooks/usePaginationStore';
import {
	asyncCallDone,
	asyncCallStart,
	updateTemplateMappingInList,
	updateTemplatesFilter,
} from '../../data/stores/templateStore';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from '../../data/stores/modalStore';
import { BudgetRanges, ProjectTypes } from '../../data/models/project';
import { TemplateMappingUpdateDTO } from '../../data/models/template';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Menu from '../common/Menu';
import { editTemplateMapping } from '../../services/template-service';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

export default function TemplateMappingTable(props) {
	const dispatch: AppDispatch = useDispatch();

	const { handleRowClick } = props;

	const {
		templateMappings: { list, count, initialized },
		stage,
	} = useSelector((state: RootState) => state.templateState);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		setPageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination(
		'templateState',
		'templateMappings',
		updateTemplatesFilter
	);

	useEffect(() => {
		dispatch(fetchTemplateMappings({}));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'name',
			direction: 'ASC',
			page: 0,
			// take: pageSize,
			// where: { default: !location.pathname.includes('custom') },
		};
		dispatch(fetchTemplateMappings(filter));
	};

	const handleEdit = (templateMapping: TemplateMappingUpdateDTO) => {
		dispatch(
			showModal({
				openId: 'addTemplateMappingModal',
				props: { templateMapping },
			})
		);
	};

	const handleArchive = async (templateMappingId: string) => {
		try {
			dispatch(asyncCallStart());
			const updatedTemplateMapping = await editTemplateMapping({
				id: templateMappingId,
				active: false,
			});
			dispatch(updateTemplateMappingInList(updatedTemplateMapping));
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	const handleUnarchive = async (templateMappingId: string) => {
		try {
			dispatch(asyncCallStart());
			const updatedTemplateMapping = await editTemplateMapping({
				id: templateMappingId,
				active: true,
			});
			dispatch(updateTemplateMappingInList(updatedTemplateMapping));
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	const columns: GridColDef[] = useMemo(() => {
		const _columns = [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 250,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
							{params.value}
						</div>
					);
				},
			},
			{
				field: 'description',
				headerName: 'Description',
				minWidth: 325,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
							{params.value}
						</div>
					);
				},
			},
			{
				field: 'projectTypeDisplay',
				headerName: 'Project Type',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
							{params.value}
						</div>
					);
				},
			},
			// {
			// 	field: 'budgetRangeDisplay',
			// 	headerName: 'Budget Range',
			// 	minWidth: 200,
			// 	disableColumnMenu: true,
			// 	sortable: false,
			// 	renderCell: (params: any) => {
			// 		return (
			// 			<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
			// 				{params.value}
			// 			</div>
			// 		);
			// 	},
			// },
			{
				field: 'templatesLength',
				headerName: 'Number of Templates',
				minWidth: 175,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<div style={{ opacity: params.row.active ? 1 : 0.5 }}>
							{params.value} Templates
						</div>
					);
				},
			},
			{
				field: 'actions',
				headerName: '',
				disableColumnMenu: true,
				renderCell: (params: any) => {
					if (params.row.active) {
						const menuItems = [
							{
								display: 'Edit',
								onClick: () => handleEdit(params.row),
								icon: (
									<EditIcon
										color={'primary'}
										fontSize={'medium'}
										sx={{ marginRight: 1 }}
									/>
								),
							},
							{
								display: 'Archive',
								onClick: () => handleArchive(params.row.id),
								icon: (
									<DeleteOutlineOutlinedIcon
										color={'warning'}
										fontSize={'medium'}
										sx={{ marginRight: 1 }}
									/>
								),
							},
						];
						return (
							<Menu
								menuItems={menuItems}
								color={'primary'}
								dropDownIcon
								alternativeIcon={() => (
									<MoreHorizIcon
										color={
											params.row.active
												? 'primary'
												: 'disabled'
										}
									/>
								)}
							/>
						);
					}
					return (
						<Tooltip title="Unarchive">
							<IconButton
								onClick={() => handleUnarchive(params.row.id)}
							>
								<UnarchiveOutlinedIcon color={'disabled'} />
							</IconButton>
						</Tooltip>
					);
				},
			},
			// {
			// 	field: 'updated',
			// 	headerName: 'Last modified',
			// 	minWidth: 120,
			// 	disableColumnMenu: true,
			// 	sortable: false,
			// 	renderCell: (params: any) =>
			// 		dayjs(params.value).format('MMM D, YYYY'),
			// },
		];
		return _columns;
	}, [handleRowClick]);

	const rows: any[] = useMemo(() => {
		const activeRows = list
			.filter((templateMapping) => templateMapping.active)
			.map((templateMapping) => ({
				...templateMapping,
				id: templateMapping.id,
				name: templateMapping.name,
				description: templateMapping.description,
				projectTypeDisplay:
					ProjectTypes[templateMapping.projectType].display ||
					templateMapping.projectType,
				budgetRangeDisplay:
					BudgetRanges[templateMapping.budgetRange]?.display ||
					templateMapping.budgetRange,
				templatesLength: templateMapping.templates?.length,
			}));

		const inactiveRows = list
			.filter((templateMapping) => !templateMapping.active)
			.map((templateMapping) => ({
				...templateMapping,
				id: templateMapping.id,
				name: templateMapping.name,
				description: templateMapping.description,
				projectTypeDisplay:
					ProjectTypes[templateMapping.projectType].display ||
					templateMapping.projectType,
				budgetRangeDisplay:
					BudgetRanges[templateMapping.budgetRange]?.display ||
					templateMapping.budgetRange,
				templatesLength: templateMapping.templates?.length,
			}));

		return [...activeRows, ...inactiveRows];
	}, [list, searchText]);

	return (
		<>
			<SimpleTable
				columns={columns}
				rows={rows}
				page={0}
				pageSize={50}
				rowCount={count}
				sortModel={[
					{
						field: 'created',
						sort: 'desc',
					},
				]}
				toolbar={
					<CustomToolbar
						searchText={searchText}
						handleTextSearch={handleTextSearch}
					/>
				}
				onSortModelChange={() => {}}
				searchText={searchText}
				// handleTextSearch={handleTextSearch}
				onPaginationModelChange={() => {}}
				loading={!initialized || stage === 'Busy'}
			/>
		</>
	);
}

const HeaderContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: space-between;
`;

interface TableToolbarProps {
	searchText?: string;
	handleTextSearch?: (localSearchText: string) => void;
}

const CustomToolbar: React.FC<TableToolbarProps> = (
	props: TableToolbarProps
) => {
	const { searchText = '', handleTextSearch } = props;
	const dispatch = useDispatch();

	const [localSearchText, setLocalSearchText] = useState(searchText);

	const handleSearch = () => {
		handleTextSearch && handleTextSearch(localSearchText);
	};

	const handleClear = () => {
		setLocalSearchText('');
		handleTextSearch && handleTextSearch('');
	};

	const handleAdd = () => {
		dispatch(
			showModal({
				openId: 'addTemplateMappingModal',
			})
		);
	};

	return (
		<StyledGridToolbarContainer>
			<HeaderContainer>
				<HeaderLeftContainer>
					<Box
						sx={{
							width: 250,
						}}
					>
						<SearchBar
							searchText={localSearchText}
							setSearchText={setLocalSearchText}
							handleTextSearch={handleSearch}
							handleClear={handleClear}
						/>
					</Box>
				</HeaderLeftContainer>
				<HeaderRightContainer>
					<GridToolbarExport />
				</HeaderRightContainer>
			</HeaderContainer>
			<AddToAstroButton onClick={handleAdd}>
				<AddIcon style={{ marginRight: 3 }} /> Add New Mapping
			</AddToAstroButton>
		</StyledGridToolbarContainer>
	);
};

export const TableHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;

const AddToAstroButton = styled(Button)`
	text-transform: none !important;
	font-weight: normal !important;
	margin-top: 15px !important;
`;

const ToggleButtonContainer = styled.div`
	margin-left: 10px;
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
	display: block !important;
	padding: 10px 10px 0 10px !important;
`;

const HeaderLeftContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-start;
`;

const HeaderRightContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`;
