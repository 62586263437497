import { createGlobalStyle } from 'styled-components';
import backgroundImage from '../images/Orbito-BG.png';

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    transition: all 0.25s linear;
  }

  #root {
    position: relative;
  }

  /* Apply the background image only when in orbito mode */
  ${(props) =>
		props.theme.palette.mode === 'dark' &&
		props.theme.palette.text.gradient.includes('linear-gradient')
			? `
    #root::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url(${backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }

    /* Ensure MUI components are transparent to show the background */
    .MuiAppBar-root, .MuiDrawer-paper {
      background-color: transparent !important;
      box-shadow: none;
    }
  `
			: ''}
`;

export default GlobalStyles;
