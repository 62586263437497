import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import { useNavigate } from 'react-router-dom';
import Button from './common/buttons/Button';
import Config from '../config';
import { BackgroundBeams } from './ui/background-beams';
import { styled } from 'styled-components';
import AstronautImage from '../images/astronauts.jpeg';
import Paper from './common/layout/Paper';
import Link from './common/Link';
import OrbitoIcon from '../images/Orbito_Logo_WhiteText.svg';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import backgroundImage from '../images/Orbito-BG.png';

export default function LoginScreen() {
	const navigate = useNavigate();
	const authState = useSelector((state: RootState) => state.authState);

	useEffect(() => {
		if (authState.astroToken) {
			navigate('/');
		}
	}, [authState, navigate]);
	// const SCOPES =
	// 	'email profile https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents'; // Add other scopes as needed
	const SCOPES =
		'email profile https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents'; // Add other scopes as needed

	const handleLogin = () => {
		const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
			Config.googleOAuth.GOOGLE_CLIENT_ID
		)}&redirect_uri=${encodeURIComponent(
			Config.googleOAuth.REDIRECT_URI
		)}&scope=${encodeURIComponent(
			SCOPES
		)}&access_type=offline&prompt=consent`;
		window.location.href = authUrl;
	};

	return (
		<Container>
			<LoginContainer>
				<LoginBox>
					<AstroLogoContainer>
						<img src={OrbitoIcon} />
					</AstroLogoContainer>
					<SubText>
						The modern, flexible and collaborative toolset designed
						for the next generation of film producers and production
						companies. Built on Google, elevated with agentic AI.
					</SubText>
					<Button
						style={{
							width: '100%',
							background:
								'linear-gradient(101deg, #4285F4 3.32%, #EA4335 63.03%, #FBBC04 122.74%)',
						}}
						onClick={handleLogin}
					>
						Login
					</Button>
					<NewText>
						New to Orbito?{' '}
						<Link
							onClick={() => navigate('/signup')}
							style={{ fontWeight: 600 }}
						>
							Sign up
						</Link>
					</NewText>
				</LoginBox>
			</LoginContainer>
			<div
				style={{ position: 'absolute', bottom: 15, display: 'inline' }}
			>
				<Link
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 12,
					}}
					onClick={() =>
						window.open(
							`${Config.baseUrl}/privacy-policy`,
							'_blank'
						)
					}
				>
					View our Privacy Policy <StyledOpenInNewOutlinedIcon />
				</Link>
				<BottomText>
					Orbito is a state of the art web based platform utilizing
					best in class film production workflows all powered through
					Google Workspace APIs. No user data obtained through Google
					APIs are used to develop, improve or train generalized AI
					and/or ML tools. No data is shared or sold to any external
					entity.
				</BottomText>
			</div>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	position: relative;
	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-image: url(${backgroundImage});
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}
`;

const NewText = styled.div`
	color: ${({ theme }) => theme.palette.text.primary};
	font-size: 16px;
	margin-top: ${({ theme }) => theme.spacing(4)};
`;

const BottomText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding-left: 100px;
	padding-right: 100px;
	text-align: center;
	color: ${({ theme }) => theme.palette.text.label};
`;

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const LoginBox = styled(Paper)`
	padding-top: ${({ theme }) => theme.spacing(6)};
	padding-bottom: ${({ theme }) => theme.spacing(6)};
	padding-right: ${({ theme }) => theme.spacing(5)};
	padding-left: ${({ theme }) => theme.spacing(5)};
	position: absolute !important;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	position: relative;
	width: 380px;
`;

const AstroLogoContainer = styled.div`
	margin-bottom: 15px;
`;

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
	margin-left: ${({ theme }) => theme.spacing(1)};
	font-size: 14px !important;
`;

const SubText = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing(4)};
	color: ${({ theme }) => theme.palette.text.label};
`;
