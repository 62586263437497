import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import Button from '../common/buttons/Button';
import { Box } from '../common/layout/Content';
import dayjs from 'dayjs';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { CircularProgress, Typography, Chip } from '@mui/material';
import Dialog, {
	DialogContent,
	DialogTitle,
	DialogActions,
} from '../common/Dialog';
import * as StripeService from '../../services/stripe-service';
import * as CompanyService from '../../services/company-service';
import { refreshCompanySuccess } from '../../data/stores/companyStore';
import { showAlert } from '../../data/stores/alertStore';
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { getStripe } from '../../services/stripe-service';
import styled from 'styled-components';
import { ActiveChip } from '../projects/ProjectStatusDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { showModal } from '../../data/stores/modalStore';

export default function CompanySubscription() {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const navigate = useNavigate();
	const { company } = useSelector((state: RootState) => state.companyState);
	const [isLoading, setIsLoading] = useState(false);
	const [clientSecret, setClientSecret] = useState<string | null>(null);
	const [showCancelDialog, setShowCancelDialog] = useState(false);

	useEffect(() => {
		if (location.state?.openSubscriptionModal) {
			handleSubscription();
		}
	}, [location.state?.openSubscriptionModal]);

	const handleSubscription = async () => {
		setIsLoading(true);
		try {
			let response;
			if (!company.subscription) {
				response = await StripeService.createSubscription();
				if (response?.clientSecret) {
					setClientSecret(response.clientSecret);
				}
			} else if (company.subscription.status === 'active') {
				setShowCancelDialog(true);
			} else if (
				company.subscription.status === 'canceled' ||
				company.subscription.status === 'canceling'
			) {
				await StripeService.reactivateSubscription(
					company.subscription.subscriptionId
				);
				const updatedCompany = await CompanyService.fetchCompany();
				dispatch(refreshCompanySuccess(updatedCompany));
				dispatch(
					showAlert({
						message: 'Subscription reactivated successfully',
						severity: 'success',
					})
				);
			} else {
				response = await StripeService.createSubscription();
				if (response?.clientSecret) {
					setClientSecret(response.clientSecret);
				}
			}
		} catch (error) {
			console.error('Subscription error:', error);
			dispatch(
				showAlert({
					message:
						'An error occurred while managing the subscription',
					severity: 'error',
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancelSubscription = async () => {
		setIsLoading(true);
		try {
			await StripeService.cancelSubscription(
				company.subscription!.subscriptionId
			);
			const updatedCompany = await CompanyService.fetchCompany();
			dispatch(refreshCompanySuccess(updatedCompany));
			dispatch(
				showAlert({
					message: 'Subscription cancelled successfully',
					severity: 'success',
				})
			);
			setShowCancelDialog(false);
		} catch (error) {
			console.error('Cancel subscription error:', error);
			dispatch(
				showAlert({
					message:
						'An error occurred while cancelling the subscription',
					severity: 'error',
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	const onComplete = async () => {
		await new Promise((resolve) => setTimeout(resolve, 500));
		const updatedCompany = await CompanyService.fetchCompany();
		dispatch(refreshCompanySuccess(updatedCompany));
		navigate('/');
		dispatch(showModal({ openId: 'googleWorkspaceVerificationModal' }));
	};

	const handleClose = () => {
		setClientSecret(null);
		setShowCancelDialog(false);
	};

	const handleManagePayment = async () => {
		setIsLoading(true);
		try {
			const { url } = await StripeService.createPortalSession();
			window.location.href = url;
		} catch (error) {
			console.error('Payment management error:', error);
			dispatch(
				showAlert({
					message:
						'An error occurred while accessing payment settings',
					severity: 'error',
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	const getSubscriptionStatusChip = () => {
		if (!company.subscription) return null;

		const { status } = company.subscription;
		let color: 'success' | 'warning' | 'error' | 'default' = 'default';
		let label = status.charAt(0).toUpperCase() + status.slice(1);

		if (status === 'active') {
			return <ActiveChip label={'Active'} sx={{ ml: 1 }} />;
		}

		switch (status) {
			case 'trialing':
				color = 'warning';
				break;
			case 'canceled':
				color = 'error';
				label = 'Cancelled';
				break;
		}

		return <Chip label={label} color={color} sx={{ ml: 1 }} />;
	};

	const renderSubscriptionStatus = () => {
		if (
			!company.subscription ||
			company.subscription.status === 'incomplete'
		) {
			return (
				<SubscriptionStatus>
					<Typography variant="body1" gutterBottom>
						No Active Subscription
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubscription}
						disabled={isLoading}
					>
						Subscribe Now
						{isLoading && (
							<CircularProgress size={24} sx={{ ml: 1 }} />
						)}
					</Button>
				</SubscriptionStatus>
			);
		}

		const { subscription } = company;
		const isActive = subscription.status === 'active';
		const isTrial = subscription.status === 'trialing';

		return (
			<Box>
				<SubscriptionStatus>
					<Typography variant="h6" gutterBottom>
						Subscription Status {getSubscriptionStatusChip()}
					</Typography>

					{isTrial && (
						<>
							<Typography variant="body1" gutterBottom>
								{dayjs(subscription.trialEnd).isBefore(dayjs())
									? 'Your free trial has ended. Please upgrade to continue using Orbito.'
									: `Free trial ends on ${dayjs(
											subscription.trialEnd
									  ).format('MMM D, YYYY')}`}
							</Typography>
							<Button
								variant="contained"
								color="primary"
								onClick={handleManagePayment}
								disabled={isLoading}
							>
								Manage Subscription
								{isLoading && (
									<CircularProgress
										size={24}
										sx={{ ml: 1 }}
									/>
								)}
							</Button>
						</>
					)}

					{isActive && (
						<>
							<Typography variant="body1" gutterBottom>
								Started:{' '}
								{dayjs(
									subscription.subscriptionStartDate
								).format('MMM D, YYYY')}
							</Typography>
							<SubscriptionButtonContainer>
								<Button
									variant="outlined"
									color="primary"
									onClick={handleManagePayment}
									disabled={isLoading}
									sx={{ mr: 2 }}
								>
									Manage Payment Method
								</Button>
								<Button
									variant="outlined"
									color="warning"
									onClick={handleSubscription}
									disabled={isLoading}
								>
									Cancel Subscription
								</Button>
							</SubscriptionButtonContainer>
						</>
					)}

					{subscription.status === 'canceling' && (
						<>
							<Typography variant="body1" gutterBottom>
								Access until:{' '}
								{dayjs(subscription.subscriptionEndDate).format(
									'MMM D, YYYY'
								)}
							</Typography>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubscription}
								disabled={isLoading}
							>
								Reactivate Subscription
							</Button>
						</>
					)}
				</SubscriptionStatus>
			</Box>
		);
	};

	return (
		<>
			<Paper gradientBorder>
				<PaperHeader>Subscription Management</PaperHeader>
				{renderSubscriptionStatus()}
			</Paper>

			<Dialog open={!!clientSecret} handleClose={handleClose}>
				<DialogTitle>Subscribe to Orbito</DialogTitle>
				<DialogContent sx={{ padding: 0, minWidth: '500px' }}>
					{clientSecret && (
						<EmbeddedCheckoutProvider
							stripe={getStripe()}
							options={{ clientSecret, onComplete }}
						>
							<EmbeddedCheckout />
						</EmbeddedCheckoutProvider>
					)}
				</DialogContent>
			</Dialog>

			<Dialog open={showCancelDialog} handleClose={handleClose}>
				<DialogTitle>Cancel Subscription</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to cancel your subscription? You
						will continue to have access until the end of your
						current billing period.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Keep Subscription
					</Button>
					<Button
						onClick={handleCancelSubscription}
						color="secondary"
						disabled={isLoading}
					>
						Cancel Subscription
						{isLoading && (
							<CircularProgress size={24} sx={{ ml: 1 }} />
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const SubscriptionStatus = styled.div`
	padding: 16px;
`;

const SubscriptionButtonContainer = styled.div`
	margin-top: 16px;
`;
