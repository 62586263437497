import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../data/store';
import { loginSuccess } from '../data/stores/authStore';
import { baseAxiosClient } from '../clients/axios-client';
import { User } from '../data/models/user';
import ScreenContainer from './common/layout/ScreenContainer';
import CenterContainer from './common/layout/CenterContainer';
import Config from '../config';
import { showAlert } from '../data/stores/alertStore';

export default function OAuth2CallbackScreen() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		// Extract the code from URL query parameters
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get('code');
		const state = queryParams.get('state');

		const handleOAuth2Callback = async (
			code: string,
			isSignup: string | null
		) => {
			try {
				const response: { user: User; astroToken: string } =
					await baseAxiosClient({
						url: `${Config.apiBaseUrl}/user/oauth2callback`,
						method: `POST`,
						data: {
							code,
							isSignup,
						},
					});
				dispatch(
					loginSuccess({
						user: response.user,
						astroToken: response.astroToken,
					})
				);
				if (isSignup) {
					navigate('/signup-company');
				} else {
					navigate('/login');
				}
			} catch (error) {
				console.log('error', error);
				if (isSignup) {
					navigate('/');
					dispatch(
						showAlert({
							message:
								'An account may already exist with this email or domain. Try signing in or resetting your password.',
							severity: 'error',
						})
					);
				} else {
					dispatch(
						showAlert({
							message:
								'An error occurred logging in, please check your credentials and try again.',
							severity: 'error',
						})
					);
				}
			}
		};
		if (code) {
			handleOAuth2Callback(
				code,
				state ? JSON.parse(state)?.isSignup : null
			);
		}
	}, [location, navigate, dispatch]);

	return (
		<ScreenContainer>
			<CenterContainer>
				<h2>Getting the Orbito Universe ready for you...</h2>
			</CenterContainer>
		</ScreenContainer>
	);
}
