import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

export const HeaderContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
`;

interface HeaderTitleProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

export const HeaderTitle: React.FC<HeaderTitleProps> = ({
	children,
	sx = {},
}) => {
	const theme = useTheme();
	const isOrbitoMode = Boolean(
		theme.palette.text.gradient?.includes('linear-gradient')
	);

	return (
		<Typography
			variant="h4"
			sx={{
				marginBottom: 2,
				...sx,
				...(isOrbitoMode && {
					background: theme.palette.text.gradient,
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor: 'transparent',
					backgroundClip: 'text',
					textFillColor: 'transparent',
				}),
			}}
		>
			{children}
		</Typography>
	);
};

export const HeaderButton = styled.div`
	flex: 1;
	text-align: right;
`;

export const SubHeader = styled.span`
	font-size: 0.8rem;
	color: ${({ theme }) => theme.palette.text.subheader};
	margin-bottom: 5px;
	margin-top: 5px;
`;
