import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import { useNavigate } from 'react-router-dom';
import Button from './common/buttons/Button';
import Config from '../config';
import { styled } from 'styled-components';
import Paper from './common/layout/Paper';
import Link from './common/Link';
import OrbitoLogo from '../images/Orbito_Logo_WhiteText.svg';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import backgroundImage from '../images/Orbito-BG.png';

export default function SignupScreen() {
	const navigate = useNavigate();
	const authState = useSelector((state: RootState) => state.authState);

	useEffect(() => {
		if (authState.astroToken) {
			navigate('/');
		}
	}, [authState, navigate]);

	const SCOPES =
		'email profile https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents';

	const handleSignup = () => {
		const state = encodeURIComponent(JSON.stringify({ isSignup: true }));
		const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
			Config.googleOAuth.GOOGLE_CLIENT_ID
		)}&redirect_uri=${encodeURIComponent(
			Config.googleOAuth.REDIRECT_URI
		)}&scope=${encodeURIComponent(
			SCOPES
		)}&state=${state}&access_type=offline&prompt=consent&include_granted_scopes=true`;
		window.location.href = authUrl;
	};

	return (
		<Container>
			<WorkspaceRequirementsBox>
				<Header>
					<HeaderText>Before you begin</HeaderText>
					<LogoContainer>
						<img src={OrbitoLogo} alt="Orbito Logo" />
					</LogoContainer>
				</Header>
				<RequirementsList>
					<RequirementItem>
						You must have an existing Google Workspace account that
						Orbito can link to.
					</RequirementItem>
					<RequirementItem>
						Your linked Google Workspace will contain all project
						files and folders created by Orbito.
					</RequirementItem>
					<RequirementItem>
						If you do not have a Google Workspace account, create
						one first before creating an account by visiting{' '}
						<Link
							onClick={() =>
								window.open(
									'https://workspace.google.com',
									'_blank'
								)
							}
						>
							workspace.google.com
						</Link>
					</RequirementItem>
				</RequirementsList>
				<Button
					style={{
						width: '100%',
						background:
							'linear-gradient(101deg, #4285F4 3.32%, #EA4335 63.03%, #FBBC04 122.74%)',
					}}
					onClick={handleSignup}
				>
					Continue
				</Button>
			</WorkspaceRequirementsBox>
			<div
				style={{ position: 'absolute', bottom: 15, display: 'inline' }}
			>
				<Link
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 12,
					}}
					onClick={() =>
						window.open(
							`${Config.baseUrl}/privacy-policy`,
							'_blank'
						)
					}
				>
					View our Privacy Policy <StyledOpenInNewOutlinedIcon />
				</Link>
				<BottomText>
					Orbito is a state of the art web based platform utilizing
					best in class film production workflows all powered through
					Google Workspace APIs. No user data obtained through Google
					APIs are used to develop, improve or train generalized AI
					and/or ML tools. No data is shared or sold to any external
					entity.
				</BottomText>
			</div>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	position: relative;
	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-image: url(${backgroundImage});
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}
`;

const WorkspaceRequirementsBox = styled(Paper)`
	width: 500px;
	padding: ${({ theme }) => theme.spacing(4)};
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const HeaderText = styled.div`
	font-size: 32px;
	color: ${({ theme }) => theme.palette.text.label};
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(1)};
`;

const LogoText = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: ${({ theme }) => theme.palette.text.primary};
`;

const RequirementsList = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(3)};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const RequirementItem = styled.div`
	color: ${({ theme }) => theme.palette.text.primary};
	font-size: 14px;
	line-height: 1.5;
`;

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
	margin-left: ${({ theme }) => theme.spacing(1)};
	font-size: 14px !important;
`;

const BottomText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding-left: 100px;
	padding-right: 100px;
	text-align: center;
	color: ${({ theme }) => theme.palette.text.label};
`;
