import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import TopicIcon from '@mui/icons-material/Topic';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useNavigate } from 'react-router-dom';
import UserNavItem from './UserNavItem';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useLocation } from 'react-router-dom';
import OrbitoLogo from '../../images/Orbito_Logo_WhiteText.svg';
import OrbitoLogoDarkText from '../../images/Orbito_Logo_DarkText.svg';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Menu from '../common/Menu';
import Button from '../common/buttons/Button';
import { showModal } from '../../data/stores/modalStore';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin } from '../../utils/auth-utils';
import { AppDispatch, RootState } from '../../data/store';
import { useEffect, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { updateSelf } from '../../data/thunks/userThunk';
import { orbitoGradient } from '../../theme/theme';
const pjson = require('../../../package.json');
const VERSION = pjson.version;

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	padding: theme.spacing(0, 1),
	marginLeft: 15,
	marginTop: 10,
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function MiniDrawer({
	children,
}: {
	children: React.ReactNode;
}) {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const location = useLocation();
	const {
		company: {
			quickBooksTimeConnection,
			subscription,
			googleDriveSetupVerified,
			name,
		},
	} = useSelector((state: RootState) => state.companyState);
	const userTheme = useSelector(
		(state: RootState) => state.authState?.user?.preferences?.theme
	);
	const tourTooltipViewed = useSelector(
		(state: RootState) =>
			state.authState?.user?.helpers?.tourTooltipViewed ?? false
	);
	const theme = useTheme();
	const isLightMode = theme?.palette?.mode === 'light';
	const [open, setOpen] = React.useState(true);
	const [tooltipOpen, setTooltipOpen] = React.useState(false);

	useEffect(() => {
		if (!tourTooltipViewed && !googleDriveSetupVerified) {
			setTooltipOpen(true);
		}
	}, [tourTooltipViewed, googleDriveSetupVerified]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleTooltipClose = () => {
		dispatch(updateSelf({ helpers: { tourTooltipViewed: true } }, false));
		setTooltipOpen(false);
	};

	const menuItems = React.useMemo(() => {
		return [
			{
				display: 'New Project',
				onClick: () => {
					dispatch(showModal({ openId: 'projectCreationModal' }));
				},
				icon: (
					<TopicOutlinedIcon
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			},
			{
				display: 'New Client',
				onClick: () => {
					dispatch(showModal({ openId: 'clientCreationModal' }));
				},
				icon: (
					<GroupsOutlinedIcon
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			},
			{
				display: 'New User',
				onClick: () => {
					dispatch(showModal({ openId: 'userCreationModal' }));
				},
				icon: (
					<ManageAccountsOutlinedIcon
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			},
		];
	}, []);

	const routes = useMemo(() => {
		const userRoutes = [...defaultRoutes];
		if (name === 'Blue Ox Films') {
			userRoutes.push({
				text: 'Configuration',
				activeIcon: <SettingsIcon />,
				icon: <SettingsOutlinedIcon />,
				path: '/configuration',
			});
		}
		return userRoutes;
	}, [name]);

	const hasActiveSubscription =
		subscription?.status === 'active' ||
		subscription?.status === 'trialing' ||
		subscription?.status === 'canceling';

	const selectedButtonColor =
		// @ts-ignore
		userTheme === 'orbito' ? orbitoGradient : theme.palette.action.selected;

	return (
		<Box
			sx={{
				display: 'flex',
				backdropFilter: 'none',
				backgroundColor: 'transparent',
			}}
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				open={open}
				sx={{
					backdropFilter: 'none',
				}}
			>
				<Toolbar
					sx={{
						justifyContent: 'flex-end',
					}}
				>
					{/* <IconButton
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton> */}
					<Tooltip
						open={tooltipOpen}
						onClose={handleTooltipClose}
						arrow
						placement="left"
						title="Click here to take a tour of Orbito"
					>
						<IconButton
							onClick={() =>
								dispatch(showModal({ openId: 'tourModal' }))
							}
						>
							<HelpOutlineOutlinedIcon />
						</IconButton>
					</Tooltip>
					<UserNavItem />
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				open={open}
				sx={{ backdropFilter: 'none' }}
			>
				<DrawerHeader>
					<img
						src={isLightMode ? OrbitoLogoDarkText : OrbitoLogo}
						style={{ height: 35 }}
					/>
					{/* <IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton> */}
				</DrawerHeader>
				{isAdmin() && (
					<NewButtonContainer>
						<Menu
							menuItems={menuItems}
							variant={'elevation'}
							color={'primary'}
							disabled={!hasActiveSubscription}
							customButton={
								<Button
									onClick={() => {}}
									variant="contained"
									color="primary"
									sx={{ textTransform: 'none' }}
									style={{
										backgroundColor: !hasActiveSubscription
											? theme.palette.action
													.disabledBackground
											: theme.palette.background.button,
										color: !hasActiveSubscription
											? theme.palette.action.disabled
											: theme.palette.text.primary,
										boxShadow:
											'0px 1px 6px rgba(0, 0, 0, 0.2)',
										borderRadius: '15px',
										padding: '12px 24px',
										border: '1px solid rgba(0, 0, 0, 0.12)',
										cursor: !hasActiveSubscription
											? 'not-allowed'
											: 'pointer',
										opacity: !hasActiveSubscription
											? 0.6
											: 1,
									}}
								>
									<AddIcon /> New
								</Button>
							}
						/>
					</NewButtonContainer>
				)}
				<List
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						marginTop: 15,
					}}
				>
					{routes.map((route, index) => (
						<ListItem
							key={route.text}
							disablePadding
							sx={{ display: 'block' }}
						>
							<ListItemButton
								onClick={() => navigate(route.path)}
								sx={{
									// minHeight: 32,
									justifyContent: open ? 'initial' : 'center',
									px: 1.5,
									cursor: 'pointer',
									background: isPathActive(
										route.path,
										location.pathname
									)
										? selectedButtonColor + ' !important'
										: 'transparent',
								}}
								disabled={!hasActiveSubscription}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 2 : 'auto',
										justifyContent: 'center',
									}}
								>
									{isPathActive(route.path, location.pathname)
										? route.activeIcon
										: route.icon}
								</ListItemIcon>
								<ListItemText
									primary={route.text}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
					<ListItem style={{ flex: 1 }} />
				</List>
			</Drawer>
			<Box
				sx={{
					flexGrow: 1,
					p: 3,
					paddingTop: '60px',
					// overflow: 'auto',
					position: 'fixed',
					top: 20,
					left: 220,
					width: `calc(100% - 215px)`,
					height: 'calc(100% - 15px)',
				}}
			>
				{children}
			</Box>
		</Box>
	);
}

const isPathActive = (path: string, currentPath: string) => {
	if (path === '/') {
		return currentPath === path;
	} else {
		return currentPath.includes(path);
	}
};

const defaultRoutes = [
	{
		text: 'Dashboard',
		activeIcon: <DashboardIcon />,
		icon: <DashboardOutlinedIcon />,
		path: '/',
	},
	{
		text: 'Projects',
		activeIcon: <TopicIcon />,
		icon: <TopicOutlinedIcon />,
		path: '/projects',
	},
	{
		text: 'Templates',
		activeIcon: <InsertDriveFileIcon />,
		icon: <InsertDriveFileOutlinedIcon />,
		path: '/templates',
	},
	{
		text: 'Clients',
		activeIcon: <GroupsIcon />,
		icon: <GroupsOutlinedIcon />,
		path: '/clients',
	},
	{
		text: 'Users',
		activeIcon: <ManageAccountsIcon />,
		icon: <ManageAccountsOutlinedIcon />,
		path: '/users',
	},
];

const NewButtonContainer = styled('div')`
	margin-left: 20px;
	margin-top: 20px;
`;
