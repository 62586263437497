import React from 'react';
import { styled } from 'styled-components';
import Paper from './common/layout/Paper';
import Input from './common/form/Input';
import Select from './common/form/Select';
import Button from './common/buttons/Button';
import { BackgroundBeams } from './ui/background-beams';
import AstroIcon from '../images/AstroLogo.svg';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { completeCompanySetup } from '../services/company-service';
import { AppDispatch, RootState } from '../data/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	asyncCallError,
	asyncCallDone,
	asyncCallStart,
	updateCompanySuccess,
} from '../data/stores/companyStore';
import ScreenBusySpinner from './common/ScreenBusySpinner';

type FormValues = {
	firstName: string;
	lastName: string;
	companyName: string;
	companyType: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	zipCode: string;
};

export default function SignupCompanyScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { user } = useSelector((state: RootState) => state.authState);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			firstName: user?.firstName,
			lastName: user?.lastName,
			companyName: '',
			companyType: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zipCode: '',
		},
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
	});

	const companyTypes = [
		{ id: 'production', display: 'Production Company' },
		{ id: 'producer', display: 'Producer' },
		{ id: 'agency', display: 'Agency' },
		{ id: 'student', display: 'Student' },
		{ id: 'brand', display: 'Brand' },
		{ id: 'studio', display: 'Studio' },
		{ id: 'network', display: 'Network' },
		{ id: 'other', display: 'Other' },
	];

	const onSubmit = async (data: FormValues) => {
		dispatch(asyncCallStart());
		try {
			const updatedCompany = await completeCompanySetup({
				firstName: data.firstName,
				lastName: data.lastName,
				name: data.companyName,
				type: data.companyType,
				address: {
					line1: data.addressLine1,
					line2: data.addressLine2,
					city: data.city,
					state: data.state,
					zip: data.zipCode,
				},
			});
			dispatch(updateCompanySuccess(updatedCompany));
			navigate('/');
		} catch (error) {
			dispatch(asyncCallError(error));
		}
		dispatch(asyncCallDone());
	};

	return (
		<Container>
			<ScreenBusySpinner />
			<AstroLogoContainer>
				<AstroText>Welcome to Orbito</AstroText>
			</AstroLogoContainer>
			<SubText>Let’s gather the beginning details!</SubText>
			<FormContainer>
				<SectionTitle>Profile Details</SectionTitle>
				<InputGrid>
					<Controller
						name="firstName"
						control={control}
						rules={{ required: 'First name is required' }}
						render={({ field }) => (
							<Input
								{...field}
								id="firstName"
								label="First name"
								error={!!errors.firstName}
								helperText={errors.firstName?.message}
							/>
						)}
					/>
					<Controller
						name="lastName"
						control={control}
						rules={{ required: 'Last name is required' }}
						render={({ field }) => (
							<Input
								{...field}
								id="lastName"
								label="Last name"
								error={!!errors.lastName}
								helperText={errors.lastName?.message}
							/>
						)}
					/>
				</InputGrid>
				<SectionTitle>Company Details</SectionTitle>
				<InputContainer>
					<Controller
						name="companyName"
						control={control}
						rules={{ required: 'Company name is required' }}
						render={({ field }) => (
							<Input
								{...field}
								id="companyName"
								label="Company name"
								fullWidth
								error={!!errors.companyName}
								helperText={errors.companyName?.message}
							/>
						)}
					/>
				</InputContainer>
				<InputContainer>
					<Controller
						name="companyType"
						control={control}
						rules={{ required: 'Company type is required' }}
						render={({ field }) => (
							<Select
								{...field}
								label="Company Type"
								options={companyTypes}
							/>
						)}
					/>
				</InputContainer>
				<InputContainer>
					<Controller
						name="addressLine1"
						control={control}
						rules={{ required: 'Address line 1 is required' }}
						render={({ field }) => (
							<Input
								{...field}
								id="addressLine1"
								label="Company address line 1"
								fullWidth
								error={!!errors.addressLine1}
								helperText={errors.addressLine1?.message}
							/>
						)}
					/>
				</InputContainer>
				<InputContainer>
					<Controller
						name="addressLine2"
						control={control}
						render={({ field }) => (
							<Input
								{...field}
								id="addressLine2"
								label="Company address line 2"
								fullWidth
								error={!!errors.addressLine2}
								helperText={errors.addressLine2?.message}
							/>
						)}
					/>
				</InputContainer>
				<InputGrid>
					<Controller
						name="city"
						control={control}
						rules={{ required: 'City is required' }}
						render={({ field }) => (
							<Input
								{...field}
								id="city"
								label="City"
								error={!!errors.city}
								helperText={errors.city?.message}
							/>
						)}
					/>
					<Controller
						name="state"
						control={control}
						rules={{
							required: 'State is required',
							validate: (value) => {
								// Allow either 2-letter state code or full state name
								const stateRegex =
									/^(?:[A-Z]{2}|Alabama|Alaska|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New Hampshire|New Jersey|New Mexico|New York|North Carolina|North Dakota|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode Island|South Carolina|South Dakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West Virginia|Wisconsin|Wyoming)$/i;
								return (
									stateRegex.test(value) ||
									'Please enter a valid US state (2-letter code or full name)'
								);
							},
						}}
						render={({ field }) => (
							<Input
								{...field}
								id="state"
								label="State"
								error={!!errors.state}
								helperText={errors.state?.message}
							/>
						)}
					/>
					<Controller
						name="zipCode"
						control={control}
						rules={{
							required: 'Zip code is required',
							pattern: {
								value: /^\d{5}(-\d{4})?$/,
								message:
									'Please enter a valid US zip code (5 digits or 5+4 format)',
							},
						}}
						render={({ field }) => (
							<Input
								{...field}
								id="zipCode"
								label="Zip code"
								error={!!errors.zipCode}
								helperText={errors.zipCode?.message}
							/>
						)}
					/>
				</InputGrid>
			</FormContainer>
			<Button
				style={{ marginTop: '24px' }}
				onClick={handleSubmit(onSubmit)}
			>
				Continue
			</Button>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	padding: 50px;
`;

const FormContainer = styled.div`
	max-width: 600px;
`;

const AstroLogoContainer = styled.div`
	display: flex;
`;

const AstroText = styled.div`
	font-size: 3rem;
	background: linear-gradient(
		91deg,
		#4285f4 1.02%,
		#ea4335 59.27%,
		#fbbc04 117.53%
	);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 700;
`;

const SubText = styled.div`
	margin-top: ${({ theme }) => theme.spacing(2)};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	color: ${({ theme }) => theme.palette.text.label};
`;

const SectionTitle = styled.div`
	text-align: left;
	font-weight: 500;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	color: ${({ theme }) => theme.palette.text.label};
`;

const InputContainer = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const InputGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
	gap: ${({ theme }) => theme.spacing(2)};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
