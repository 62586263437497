import React, { useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import {
	archiveProject,
	updateProjectClient,
} from '../../../services/project-service';
import { Project } from '../../../data/models/project';
import ModalHeader from '../../common/ModalHeader';
import Dialog, {
	DialogActions,
	DialogContent,
	DialogTitle,
} from '../../common/Dialog';
import Button from '../../common/buttons/Button';
import { useDispatch } from 'react-redux';
import {
	asyncCallDone,
	asyncCallStart,
	refreshProjectSuccess,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
import { formatNamespaceForProject } from '../../../utils/project-utils';
import useClientsList from '../../../hooks/useClientsList';
import Autocomplete, {
	AutocompleteOption,
} from '../../common/form/AutoComplete';

interface ProjectChangeClientProps {
	open: boolean;
	handleClose: () => void;
	project: Project;
}
export default function ProjectChangeClient(props: ProjectChangeClientProps) {
	const { open, handleClose, project } = props;

	const dispatch = useDispatch();
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [newClient, setNewClient] = useState<AutocompleteOption | null>(null);

	const { dropdownOptions: clientsDropDownOptions } = useClientsList(open);

	const handleChangeClient = async () => {
		if (!newClient) return;
		try {
			handleClose();
			setConfirmationModalOpen(false);
			dispatch(asyncCallStart());
			const updatedProject = await updateProjectClient(
				project.id,
				newClient.id
			);
			dispatch(refreshProjectSuccess(updatedProject));
			dispatch(
				showAlert({
					message: 'Successfully changed the client.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred changing the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ModalHeader>Change Client</ModalHeader>
			<Label>Name</Label>
			<Info>{project.namespace}</Info>
			<Label>Current Client</Label>
			<Info>{project.client.name}</Info>
			<Autocomplete
				id={'client'}
				label={'New Client'}
				value={newClient}
				options={clientsDropDownOptions}
				onChange={(e, value) => {
					setNewClient(value);
				}}
				// error={!!errors.client}
				// helperText={errors.client?.message}
			/>
			<SaveCancelContainer>
				<SaveCancelButtons
					handleSave={() => setConfirmationModalOpen(true)}
					handleCancel={handleClose}
					saveText={'Update'}
					saveDisabled={!newClient}
				/>
			</SaveCancelContainer>
			<Dialog
				open={confirmationModalOpen}
				handleClose={() => setConfirmationModalOpen(false)}
			>
				<DialogTitle>Confirm Project Client Change</DialogTitle>
				<DialogContent>
					<Label>Current Client</Label>
					<Info>{project.client.name}</Info>
					<Label>New Client</Label>
					<Info>{newClient?.display}</Info>
					<Label>New Namespace</Label>
					<Info>
						{formatNamespaceForProject(
							project.number,
							newClient?.display || '',
							project.name
						)}
					</Info>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setConfirmationModalOpen(false)}
						variant={'text'}
					>
						Cancel
					</Button>
					<Button onClick={handleChangeClient}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</Modal>
	);
}

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
