import React, { useEffect, useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { formatNamespaceForProject } from '../../../utils/project-utils';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import { fetchNextProjectNumber } from '../../../services/project-service';
import BusySpinner from '../../common/BusySpinner';
import { ProjectTypes } from '../../../data/models/project';

interface ProjectCreationConfirmationModalProps {
	open: boolean;
	handleSave: () => void;
	handleClose: () => void;
	previewData: any;
}
export default function ProjectCreationConfirmationModal(
	props: ProjectCreationConfirmationModalProps
) {
	const { open, handleSave, handleClose, previewData } = props;

	const [busy, setBusy] = useState(false);
	const [projectNumber, setProjectNumber] = useState<number | null>(null);

	useEffect(() => {
		const fetchProjectNumber = async () => {
			setBusy(true);
			const number = await fetchNextProjectNumber().catch((e) => {
				console.error(e);
			});
			setProjectNumber(number || null);
			setBusy(false);
		};
		if (open) {
			fetchProjectNumber();
		}
	}, [open]);

	return (
		<Modal open={open} handleClose={handleClose}>
			{open && !busy ? (
				<>
					{busy ? (
						<BusySpinner />
					) : (
						<ModalContent>
							<Label>Project Namespace</Label>
							<Namespace>
								{formatNamespaceForProject(
									projectNumber,
									previewData.client,
									previewData.name
								)}
							</Namespace>
							<Label>Project Type</Label>
							<ProjectType>
								{ProjectTypes[previewData.type]?.display}
							</ProjectType>
							<Label>Workspace Members</Label>
							<WorkspaceMembers>
								{previewData.workspaceMembers
									.map((member) => member.display)
									.join(', ')}
							</WorkspaceMembers>
							<SaveCancelButtons
								handleSave={handleSave}
								handleCancel={handleClose}
								saveText={'Create'}
							/>
						</ModalContent>
					)}
				</>
			) : (
				<></>
			)}
		</Modal>
	);
}

const Namespace = styled.div`
	font-weight: bold;
	font-size: 18px;
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const ProjectType = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const WorkspaceMembers = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const ModalContent = styled.div`
	min-width: 450px;
`;
