import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../data/store';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from '../common/form/Input';
import { Grid } from '../common/layout/Content';
import { styled } from 'styled-components';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import isEmpty from 'lodash/isEmpty';
import { updateCompany } from '../../data/thunks/companyThunk';
import { v4 as uuid } from 'uuid';
import BusySpinner from '../common/BusySpinner';
import { getQuickLinkIconFromUrl } from '../../utils/company-utils';

const QuickLinks = ({ links, initialized }) => {
	const dispatch = useDispatch<AppDispatch>();
	const { handleSubmit, control, reset, formState, watch } = useForm({
		defaultValues: {
			links: links?.length ? links : [{ title: '', url: '', icon: '' }],
		},
		mode: 'onBlur',
	});
	const resetForm = () => {
		reset({
			links: links?.length ? links : [{ title: '', url: '', icon: '' }],
		});
	};
	useEffect(() => {
		resetForm();
	}, [links]);

	const { isValid, errors, dirtyFields } = formState;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'links',
	});

	const handleSave = (data) => {
		dispatch(
			updateCompany({
				quickLinks: data.links.map((l) => ({
					...l,
					id: l.id || uuid(),
				})),
			})
		);
	};

	return (
		<Paper gradientBorder>
			<SaveCancelButtons
				handleSave={handleSubmit(handleSave)}
				handleCancel={() => resetForm()}
				saveDisabled={isEmpty(dirtyFields) || !isValid}
				hidden={isEmpty(dirtyFields)}
				cardDisplay
			/>
			<PaperHeader>Quick Links</PaperHeader>
			{initialized ? (
				<LinksContainer>
					{fields.map((link, index) => (
						<LinkRow>
							<EditableLink
								fields={fields}
								append={append}
								remove={remove}
								link={link}
								index={index}
								control={control}
								watch={watch}
								errors={errors}
							/>
						</LinkRow>
					))}
				</LinksContainer>
			) : (
				<BusySpinner />
			)}
		</Paper>
	);
};

const LinksContainer = styled.div`
	margin: 10px;
	display: flex;
	flex-direction: column;
`;

const LinkRow = styled.div`
	margin-top: 20px;
`;

const EditableLink = ({
	fields,
	append,
	remove,
	link,
	index,
	control,
	watch,
	errors,
}) => {
	const urlValue = watch(`links.${index}.url`);

	return (
		<Grid container spacing={3}>
			<Grid item xs={3} md={3}>
				<Controller
					name={`links.${index}.url`}
					control={control}
					rules={{ required: true }}
					render={({ field }) => {
						return (
							<Input
								{...field}
								id={index}
								label={'Enter URL'}
								error={errors?.links?.[index]?.url}
								helperText={
									errors?.links?.[index]?.url?.message
								}
								fullWidth
							/>
						);
					}}
				/>
			</Grid>
			<Grid item style={{ display: 'flex', alignItems: 'center' }}>
				{getIcon(urlValue || '')}
			</Grid>
			<Grid item xs={3} md={3}>
				<DisplayFlex>
					<Controller
						name={`links.${index}.title`}
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<Input
									{...field}
									id={index}
									label={'Enter Title'}
									error={errors?.links?.[index]?.title}
									helperText={
										errors?.links?.[index]?.title?.message
									}
									fullWidth
								/>
							);
						}}
					/>
				</DisplayFlex>
			</Grid>
			<Grid item style={{ display: 'flex', alignItems: 'center' }}>
				<div>
					{(fields.length > 1 || index !== 0) && (
						<IconButton onClick={() => remove(index)}>
							<DeleteIcon color={'error'} fontSize={'small'} />
						</IconButton>
					)}
					{index === fields.length - 1 && (
						<IconButton
							onClick={() =>
								append({ title: '', url: '', icon: '' })
							}
						>
							<AddOutlinedIcon
								color={'primary'}
								fontSize={'small'}
							/>
						</IconButton>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

const getIcon = (url) => {
	const quickLinkIcon = getQuickLinkIconFromUrl(url);
	return (
		<img
			style={{ height: 25, objectFit: 'cover' }}
			src={quickLinkIcon.icon}
			alt={quickLinkIcon.alt}
		/>
	);
};

const DisplayFlex = styled.div`
	display: flex;
`;

export default QuickLinks;
