import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { SxProps, Theme } from '@mui/material';

interface DotsStepperProps {
	/** Total number of steps */
	totalSteps: number;
	/** Current active step (0-based index) */
	activeStep: number;
	/** Callback fired when the next button is clicked */
	onNext?: () => void;
	/** Callback fired when the back button is clicked */
	onBack?: () => void;
	/** Custom styles for the stepper component */
	sx?: SxProps<Theme>;
	/** Custom text for the next button */
	nextButtonText?: string;
	/** Custom text for the back button */
	backButtonText?: string;
	/** Whether to disable the next button */
	disableNext?: boolean;
	/** Whether to disable the back button */
	disableBack?: boolean;
	/** Whether to show navigation buttons */
	showButtons?: boolean;
}

export const DotsStepper: React.FC<DotsStepperProps> = ({
	totalSteps,
	activeStep,
	onNext,
	onBack,
	sx = { maxWidth: 400, flexGrow: 1 },
	nextButtonText = 'Next',
	backButtonText = 'Back',
	disableNext,
	disableBack,
	showButtons = true,
}) => {
	const theme = useTheme();

	return (
		<MobileStepper
			variant="dots"
			steps={totalSteps}
			position="static"
			activeStep={activeStep}
			sx={{
				...sx,
				background: 'transparent',
				padding: 0,
				'& .MuiMobileStepper-dots': {
					gap: '8px',
				},
				'& .MuiMobileStepper-dot': {
					backgroundColor: theme.palette.grey[400],
					width: '8px',
					height: '8px',
				},
				'& .MuiMobileStepper-dotActive': {
					backgroundColor: theme.palette.primary.main,
				},
			}}
			nextButton={
				showButtons ? (
					<Button
						size="small"
						onClick={onNext}
						disabled={disableNext ?? activeStep === totalSteps - 1}
					>
						{nextButtonText}
						{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
						) : (
							<KeyboardArrowRight />
						)}
					</Button>
				) : null
			}
			backButton={
				showButtons ? (
					<Button
						size="small"
						onClick={onBack}
						disabled={disableBack ?? activeStep === 0}
					>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
						) : (
							<KeyboardArrowLeft />
						)}
						{backButtonText}
					</Button>
				) : null
			}
		/>
	);
};
