import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme, PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Palette {
		gradient?: string;
	}
	interface PaletteOptions {
		gradient?: string;
	}
	interface TypeText {
		label?: string;
		divider?: string;
		subheader?: string;
		gradient?: string;
	}
	interface TypeAction {
		pending?: string;
	}
	interface TypographyVariants {
		gradient: React.CSSProperties;
	}
	interface TypographyVariantsOptions {
		gradient?: React.CSSProperties;
	}
	interface PaletteMode {
		orbito: 'orbito';
	}
	interface TypeBackground {
		button?: string;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		gradient: true;
	}
}

export const orbitoGradient =
	'linear-gradient(101deg, #4285F4 3.32%, #EA4335 63.03%, #FBBC04 122.74%)';
// linear-gradient(91deg, #4285F4 1.02%, #EA4335 59.27%, #FBBC04 117.53%)

export function getTheme(mode: 'light' | 'dark' | 'orbito') {
	let theme = createTheme({
		palette: {
			mode: mode === 'orbito' ? 'dark' : mode,
			primary: {
				main: '#1f97f4',
			},
			secondary: {
				main: '#19857b',
			},
			success: {
				main: mode === 'light' ? '#31AD37' : '#67e56d',
				dark: mode === 'light' ? '#E9FDEA' : '#2a562b',
			},
			error: {
				// main: '#f44336',
				main: '#F26868',
			},
			warning: {
				main: '#ff9800',
			},
			info: {
				main: mode === 'light' ? '#2196f3' : '#53A7F0',
				// @ts-ignore
				secondary: mode === 'light' ? '#d3ebfd' : '#53A7F0',
			},
			neutral: {
				main: '#E0E0E0',
				light: '#E7E7E7',
			},
			background: {
				default: mode === 'light' ? '#F9FAFD' : '#191b1d',
				paper: mode === 'light' ? '#fff' : '#1f2125',
				button: mode === 'light' ? '#fff' : '#27292d',
				// default:
				// 	mode === 'orbito'
				// 		? '#0A0A0A'
				// 		: mode === 'light'
				// 		? '#F9FAFD'
				// 		: '#191b1d',
				// paper:
				// 	mode === 'orbito'
				// 		? '#111111'
				// 		: mode === 'light'
				// 		? '#fff'
				// 		: '#1f2125',
			},
			divider: mode === 'light' ? '#DCDCDC' : '#9A9CA5',
			text: {
				primary: mode === 'light' ? '#1E1E1E' : '#ffffff',
				secondary: mode === 'light' ? '#444746' : '#bbbbbb',
				// @ts-ignore
				gradient:
					mode === 'orbito'
						? 'linear-gradient(91deg, #4285F4 1.02%, #EA4335 59.27%, #FBBC04 117.53%)'
						: mode === 'light'
						? '#1E1E1E'
						: '#ffffff',
				// @ts-ignore
				label: mode === 'light' ? '#00000099' : '#9A9CA5',
				divider: mode === 'light' ? '#DCDCDC' : '#9A9CA5',
				subheader: mode === 'light' ? '#00000099' : '#ffffff',
			},
			action: {
				selected: mode === 'light' ? '#C2E7FF' : '#1f97f4',
				// selected:
				// 	mode === 'orbito'
				// 		? orbitoGradient
				// 		: mode === 'light'
				// 		? '#C2E7FF'
				// 		: '#1f97f4',
				// @ts-ignore
				pending: mode === 'light' ? '#999999' : '#9A9CA5',
			},
			status: {
				building: {
					text: mode === 'light' ? '#00000099' : '#1f97f4',
					background: mode === 'light' ? '#d3ebfd' : '#1f384e',
				},
				queued: {
					text: mode === 'light' ? '#00000099' : '#545455',
					background: mode === 'light' ? '#E7E7E7' : '#E1E1E1',
				},
			},
		},
		typography: {
			fontFamily: 'Roboto, Arial, sans-serif',
			h1: {
				fontSize: '2.2rem',
				fontWeight: 500,
			},
			// Add more typography styles here
		},
		spacing: 8, // The base spacing unit
		borderRadius: 5, // The global border radius
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
		components: {
			// Component style overrides go here
			MuiButton: {
				styleOverrides: {
					// root: {
					// 	...(mode === 'orbito' && {
					// 		background:
					// 			'linear-gradient(101deg, #4285F4 3.32%, #EA4335 63.03%, #FBBC04 122.74%)',
					// 		color: '#ffffff',
					// 		'&:hover': {
					// 			background:
					// 				'linear-gradient(101deg, #3b76d8 3.32%, #d13c30 63.03%, #e2ab03 122.74%)',
					// 		},
					// 	}),
					// },
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor:
							mode === 'light' ? '#F9FAFD' : '#191b1d',
						boxShadow: 'none',
						// mode === 'orbito'
						// 		? '#0A0A0A'
						// 		: mode === 'light'
						// 		? '#F9FAFD'
						// 		: '#191b1d',
						// boxShadow:
						// 	mode === 'orbito'
						// 		? '0px 1px 3px rgba(66, 133, 244, 0.1)'
						// 		: 'none',
						backgroundImage: 'none',
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor:
							mode === 'light' ? '#F9FAFD' : '#191b1d',
						borderRight: 'none',
						// mode === 'orbito'
						// 		? '#0A0A0A'
						// 		: mode === 'light'
						// 		? '#F9FAFD'
						// 		: '#191b1d',
						// borderRight:
						// 	mode === 'orbito'
						// 		? '1px solid rgba(66, 133, 244, 0.1)'
						// 		: 'none',
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						paddingRight: 10,
					},
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						paddingTop: 0,
						paddingBottom: 0,
						marginBottom: 10,
						marginLeft: 10,
						borderRadius: 50,
						color: mode === 'light' ? 'black' : '#ffffff',
						// color:
						// 	mode === 'orbito'
						// 		? '#ffffff'
						// 		: mode === 'light'
						// 		? 'black'
						// 		: '#ffffff',
						// '&.Mui-selected':
						// 	mode === 'orbito'
						// 		? {
						// 				background:
						// 					'linear-gradient(101deg, rgba(66, 133, 244, 0.1) 3.32%, rgba(234, 67, 53, 0.1) 63.03%, rgba(251, 188, 4, 0.1) 122.74%)',
						// 		  }
						// 		: {},
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						color: mode === 'light' ? 'black' : '#ffffff',
					},
				},
			},
		},
		// Define additional custom properties here if needed
	});

	theme = responsiveFontSizes(theme); // Enhance the theme for responsive fonts

	return theme;
}
