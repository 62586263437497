import React from 'react';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { styled } from 'styled-components';
import Link from '../common/Link';

const KeyLinks = () => {
	return (
		<Paper gradientBorder>
			<ContentContainer>
				<PaperHeader>Key Links</PaperHeader>
				<KeyLinksContainer>
					<Link
						onClick={() =>
							window.open(
								'https://docs.google.com/document/d/1-8DA_7c8Y8llQVXq0st-cGTSCr4TE9xvcxMeBGM_Cnc/edit?usp=drive_link',
								'_blank'
							)
						}
					>
						Getting Started with Orbito
					</Link>
					<Link
						onClick={() =>
							window.open(
								'https://docs.google.com/document/d/1pYapVRyX1LhOV9b70DhPKDgEWUDxRJ2lHfqOWlhlkXw/edit?usp=drive_link',
								'_blank'
							)
						}
					>
						Guidelines and Best Practices
					</Link>
					<Link
						onClick={() =>
							window.open(
								'https://docs.google.com/document/d/1uBLkmX_lcQOIjG0F0vfkYt9UlO2nyTGdZTKkrrXituY/edit?usp=drive_link',
								'_blank'
							)
						}
					>
						User Management
					</Link>
				</KeyLinksContainer>
			</ContentContainer>
		</Paper>
	);
};

export default KeyLinks;

const ContentContainer = styled.div`
	min-height: 200px;
	display: flex;
	flex-direction: column;
`;

const KeyLinksContainer = styled.div`
	padding: 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
