const environment = process.env.REACT_APP_STAGE || 'dev';

const pjson = require('../package.json');

const VERSION = `${pjson.version}.${pjson.sprint}.${
	pjson.build === '0' ? Date.now() : pjson.build
}`;
const RELEASE = pjson.version;

const localIP = '172.20.10.6';

const config: any = {
	mobile: {
		environment,
		release: RELEASE,
		version: VERSION,
		baseUrl: `http://${localIP}:3000`,
		apiBaseUrl: `http://${localIP}:8080`,
		googleOAuth: {
			GOOGLE_CLIENT_ID:
				'37450922555-lsd1bmeclf7vr36qb0slqkh3f9970ogf.apps.googleusercontent.com',
			REDIRECT_URI: `http://${localIP}:3000/oauth2callback`,
		},
	},
	local: {
		environment,
		release: RELEASE,
		version: VERSION,
		baseUrl: 'http://localhost:3000',
		apiBaseUrl: 'http://localhost:8080',
		googleOAuth: {
			GOOGLE_CLIENT_ID:
				'37450922555-lsd1bmeclf7vr36qb0slqkh3f9970ogf.apps.googleusercontent.com',
			REDIRECT_URI: `http://localhost:3000/oauth2callback`,
		},
	},
	dev: {
		environment,
		release: RELEASE,
		version: VERSION,
		baseUrl: 'https://astrowebs.com',
		apiBaseUrl: 'https://api.astrowebs.com',
		googleOAuth: {
			GOOGLE_CLIENT_ID:
				'37450922555-lsd1bmeclf7vr36qb0slqkh3f9970ogf.apps.googleusercontent.com',
			REDIRECT_URI: `https://astrowebs.com/oauth2callback`,
		},
	},
	prod: {
		environment,
		release: RELEASE,
		version: VERSION,
		baseUrl: 'https://orbito.app',
		apiBaseUrl: 'https://api.orbito.app',
		googleOAuth: {
			GOOGLE_CLIENT_ID:
				'37450922555-lsd1bmeclf7vr36qb0slqkh3f9970ogf.apps.googleusercontent.com',
			REDIRECT_URI: `https://orbito.app/oauth2callback`,
		},
	},
};

export default config[environment];
