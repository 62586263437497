import React from 'react';
import { styled } from 'styled-components';

export default function ScreenContainer({
	children,
	style,
}: {
	children: React.ReactNode;
	style?: React.CSSProperties;
}) {
	return <StyledContainer style={{ ...style }}>{children}</StyledContainer>;
}

const StyledContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	overflow: scroll;
	height: 100%;
`;
