import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface RadioButtonsProps {
	label: string;
	name: string;
	options: { value: string; label: string }[];
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
	label,
	name,
	options,
	value,
	onChange,
	disabled,
}) => {
	return (
		<FormControl>
			<FormLabel id={`${name}-label`}>{label}</FormLabel>
			<RadioGroup
				aria-labelledby={`${name}-label`}
				value={value}
				name={name}
				onChange={onChange}
			>
				{options.map((option) => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						control={<Radio />}
						label={option.label}
						disabled={disabled}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioButtons;
