import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../common/Modal';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import ModalHeader from '../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../data/stores/alertStore';
import Autocomplete, { AutocompleteOption } from '../common/form/AutoComplete';
import { AppDispatch, RootState } from '../../data/store';
import { fetchGoogleUsers } from '../../services/company-service';
import { GoogleUser, UserDTO } from '../../data/models/user';
import { createUsersFromGoogleUsers } from '../../data/thunks/userThunk';
import { closeModal } from '../../data/stores/modalStore';

export default function AddUsersModal() {
	const { openId } = useSelector((state: RootState) => state.modalState);

	const open = openId === 'userCreationModal';

	const dispatch = useDispatch<AppDispatch>();
	const { users } = useSelector((state: RootState) => state.companyState);

	const [googleUsers, setGoogleUsers] = useState<GoogleUser[]>([]);
	const [selectedGoogleUsers, setSelectedGoogleUsers] = useState<
		AutocompleteOption[]
	>([]);

	useEffect(() => {
		const fetchGoogleUsersAsync = async () => {
			const response = await fetchGoogleUsers();
			setGoogleUsers(response);
		};
		if (open) {
			fetchGoogleUsersAsync();
		}
	}, [open]);

	const userDropdownOptions = useMemo(() => {
		const usersIds = users.list.map((user) => user.googleId);
		const availableGoogleUsers = googleUsers.filter(
			(googleUser) => !usersIds.includes(googleUser.id)
		);

		return availableGoogleUsers.map((user) => ({
			id: user.id,
			display: user.primaryEmail,
		}));
	}, [googleUsers, users.list]);

	const handleClose = () => {
		dispatch(closeModal());
		setSelectedGoogleUsers([]);
	};

	const onSave = () => {
		handleClose();
		const usersToCreate: UserDTO[] = [];

		for (const user of selectedGoogleUsers) {
			const googleUser = googleUsers.find((u) => u.id === user.id);
			if (!googleUser) {
				dispatch(
					showAlert({
						message: 'An error occurred creating the client.',
						severity: 'error',
					})
				);
				return;
			}
			usersToCreate.push({
				googleId: googleUser.id,
				email: googleUser.primaryEmail,
				firstName: googleUser.name.givenName,
				lastName: googleUser.name.familyName,
			});
		}

		dispatch(createUsersFromGoogleUsers(usersToCreate));
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<Container>
				<ModalHeader>Add Orbito Users from Google</ModalHeader>
				<Autocomplete
					id={'client'}
					label={'Google Users'}
					value={selectedGoogleUsers}
					options={userDropdownOptions}
					onChange={(e, value) => {
						setSelectedGoogleUsers(value);
					}}
					multiple
				/>
				<SaveCancelContainer>
					<SaveCancelButtons
						handleSave={onSave}
						handleCancel={handleClose}
						saveText={'Create'}
						saveDisabled={selectedGoogleUsers.length === 0}
					/>
				</SaveCancelContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	min-width: 400px;
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
