import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import { closeModal } from '../data/stores/modalStore';
import Modal from './common/Modal';
import ScreenBusySpinner from './common/ScreenBusySpinner';
import { styled } from 'styled-components';
import Button from './common/buttons/Button';
import { updateSelf } from '../data/thunks/userThunk';
import Link from './common/Link';
import Config from '../config';
import { useLocation } from 'react-router-dom';

export default function UserAgreementModal() {
	const { user } = useSelector((state: RootState) => state.authState);
	const {
		company: { subscription },
	} = useSelector((state: RootState) => state.companyState);
	const location = useLocation();

	const open =
		!!user?.id &&
		!user?.acceptedTermsAndConditions &&
		location.pathname !== '/terms-and-conditions' &&
		location.pathname !== '/signup-company' &&
		subscription?.status !== 'incomplete';

	const dispatch = useDispatch<AppDispatch>();

	const handleClose = () => {
		dispatch(closeModal());
	};

	const handleAccept = () => {
		dispatch(
			updateSelf({
				acceptedTermsAndConditions: true,
				acceptedTermsAndConditionsDate: new Date(),
			})
		);
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ScreenBusySpinner />
			<FormContainer>
				<LinkContainer>
					By using the services provided by Astro I, {user?.firstName}{' '}
					{user?.lastName}, are agreeing to abide by the terms and
					conditions set forth within our policies stated{' '}
					<Link
						onClick={() =>
							window.open(
								`${Config.baseUrl}/terms-and-conditions`,
								'_blank'
							)
						}
					>
						here
					</Link>
					.
				</LinkContainer>
				<Button onClick={handleAccept}>Accept</Button>
			</FormContainer>
		</Modal>
	);
}

const LinkContainer = styled.div`
	margin-bottom: 20px;
`;

const FormContainer = styled.div`
	text-align: center;
`;
