import React, { useEffect, useMemo } from 'react';
import Modal from '../common/Modal';
import { styled } from 'styled-components';
import ModalHeader from '../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { closeModal } from '../../data/stores/modalStore';
import {
	deleteCompanyTemplate,
	fetchTemplateMappings,
} from '../../data/thunks/templateThunk';
import Button from '../common/buttons/Button';
import { TemplateMapping } from '../../data/models/template';
import { AsyncStage } from '../../data/reduxCommon';

export default function TemplateGoogleFileNotFoundModal() {
	const {
		openId,
		props: { companyTemplate },
	} = useSelector((state: RootState) => state.modalState);

	const open = openId === 'templateGoogleFileNotFoundModal';

	const dispatch = useDispatch<AppDispatch>();

	const {
		templateMappings: { list: mappingsList },
		stage,
	} = useSelector((state: RootState) => state.templateState);

	useEffect(() => {
		dispatch(fetchTemplateMappings({}));
	}, []);

	const existsInMapping = useMemo(() => {
		const mappings: TemplateMapping[] = [];
		if (mappingsList.length > 0 && companyTemplate) {
			mappingsList.forEach((mapping) => {
				mapping.templates.forEach((mappingTemplate) => {
					if (
						mappingTemplate.activeVersion.googleId ===
						companyTemplate.activeVersion.googleId
					) {
						mappings.push(mapping);
					}
				});
			});
		}
		return mappings;
	}, [mappingsList, companyTemplate]);

	const handleClose = () => {
		dispatch(closeModal());
	};

	const handleDelete = async () => {
		await dispatch(deleteCompanyTemplate(companyTemplate?.id));
		handleClose();
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<Container>
				<ModalHeader>Google File Not Found</ModalHeader>
				<div>Custom Template:</div>
				<BoldText>{companyTemplate?.name}</BoldText>
				<TextParagraph>
					The associated Google file for this custom template is
					missing from the “ORBITO - CUSTOM TEMPLATES” folder. As a
					result, this template cannot be used when creating a new
					project.
				</TextParagraph>
				{existsInMapping.length > 0 && (
					<>
						<TextParagraph>
							This template appears in the following mappings:
						</TextParagraph>
						{existsInMapping.map((mapping) => (
							<BoldText key={mapping.id}>{mapping.name}</BoldText>
						))}
					</>
				)}
				<TextParagraph>
					If this template is not needed, press the delete button
					below to remove template{' '}
					{existsInMapping.length > 0
						? 'from here and the mappings'
						: 'from here'}
					.
				</TextParagraph>
				<SaveCancelContainer>
					<Button
						onClick={handleDelete}
						color="error"
						variant="outlined"
						disabled={stage === AsyncStage.Busy}
					>
						Delete
					</Button>
				</SaveCancelContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	min-width: 400px;
`;

const BoldText = styled.div`
	font-weight: 600;
	font-size: 18px;
`;

const TextParagraph = styled.div`
	margin-top: 20px;
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;

const ErrorText = styled.div`
	color: ${(props) => props.theme.palette.error.main};
	margin-bottom: 20px;
	margin-top: 20px;
	font-weight: 600;
`;
