import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import Modal from './common/Modal';
import { styled } from 'styled-components';
import { DotsStepper } from './common/DotsStepper';
import Link from './common/Link';
import CopyableText from './common/CopyableText';
import { closeModal } from '../data/stores/modalStore';

// Import images
import TourGraphic1 from '../images/Tour-Graphic-1.svg';
import TourGraphic2 from '../images/Tour-Graphic-2.svg';
import TourGraphic3 from '../images/Tour-Graphic-3.svg';
import TourGraphic4 from '../images/Tour-Graphic-4.svg';
import TourGraphic5 from '../images/Tour-Graphic-5.svg';
import TourGraphic6 from '../images/Tour-Graphic-6.svg';
import Button from './common/buttons/Button';

export default function TourModal() {
	const dispatch = useDispatch<AppDispatch>();
	const { openId } = useSelector((state: RootState) => state.modalState);
	const [activeStep, setActiveStep] = useState(0);

	const handleClose = () => {
		dispatch(closeModal());
		setActiveStep(0);
	};

	const handleNext = () => {
		setActiveStep((prev) => Math.min(prev + 1, tourSteps.length - 1));
	};

	const handleBack = () => {
		setActiveStep((prev) => Math.max(prev - 1, 0));
	};

	// Tour steps configuration
	const tourSteps: TourStep[] = [
		{
			title: "Let's take a tour!",
			description: (
				<>
					<StepText>
						Orbito brings you a best in class production toolset,
						seamlessly integrated with the Google platform that
						every producer inherently trusts.
					</StepText>
					<StepText>
						Start with a quick guided tour to familiarize yourself
						with Orbito's features and navigation. If you ever need
						to revisit the tour, you can find it anytime in the Help
						Center (found by click the '?' in the top right
						application bar).
					</StepText>
					<Button style={{ marginTop: '16px' }} onClick={handleNext}>
						Start Tour
					</Button>
				</>
			),
			image: TourGraphic1,
		},
		{
			title: 'Navigating Orbito',
			description: (
				<>
					<StepSection>
						<StepTitle>Dashboard</StepTitle>
						<StepText>
							The Dashboard is your central hub. Here, you'll find
							an overview of your recent activities, key metrics,
							and quick access to important features.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Clients</StepTitle>
						<StepText>
							In the Clients section, you can manage all your
							client information - Add new clients or view client
							details.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Projects</StepTitle>
						<StepText>
							The Projects tab lets you create and manage your
							projects. Access project details, update needed
							templates, user access and more.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Templates</StepTitle>
						<StepText>
							Templates provide you with pre-designed frameworks
							to simplify your work or manage your own custom
							templates for your projects.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Users</StepTitle>
						<StepText>
							The Users section is where you can manage team
							members and their roles. Add or remove users, assign
							permissions, and ensure everyone has the access they
							need.
						</StepText>
					</StepSection>
				</>
			),
			image: TourGraphic2,
			buttons: (
				<NavigationButtons>
					<NavButton onClick={handleBack}>
						<NavText>← PREV</NavText>
					</NavButton>
					<NavButton onClick={handleNext}>
						<NavText>NEXT →</NavText>
					</NavButton>
				</NavigationButtons>
			),
		},
		{
			title: 'Orbito Dashboard',
			description: (
				<>
					<StepText>
						Your Dashboard is designed to be your central command
						center, offering you a snapshot of your key activities
						and easy access to important features:
					</StepText>
					<StepSection>
						<StepTitle>Quick Links</StepTitle>
						<StepText>
							Quick Links provide you with immediate access to
							frequently used tools and sections. Use these
							shortcuts to streamline your workflow and make
							Orbito the center of your universe.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Created Projects</StepTitle>
						<StepText>
							Here, you can view a list of all the projects you've
							started.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Closed Projects</StepTitle>
						<StepText>
							In the Closed Projects area, find a record of
							projects that have been completed or closed.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Helpful Links</StepTitle>
						<StepText>
							This section offers additional resources and support
							options to assist you in using the application
							effectively.
						</StepText>
					</StepSection>
				</>
			),
			image: TourGraphic3,
			buttons: (
				<NavigationButtons>
					<NavButton onClick={handleBack}>
						<NavText>← PREV</NavText>
					</NavButton>
					<NavButton onClick={handleNext}>
						<NavText>NEXT →</NavText>
					</NavButton>
				</NavigationButtons>
			),
		},
		{
			title: 'Adding your team',
			description: (
				<>
					<StepText>
						Ready to collaborate? Adding team members is quick and
						easy:
					</StepText>
					<StepSection>
						<StepTitle>Tap the "New" Button</StepTitle>
						<StepText>
							Look for the circular "New" button with a + icon and
							tap it. This will open a menu of options.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Select "New User"</StepTitle>
						<StepText>
							From the menu, choose "New User". This will start
							the process of adding a new team member.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Choose from Google Workspace</StepTitle>
						<StepText>
							A list of users from your Google Workspace will
							appear. Scroll find the team member you want to add.
							Tap on their name to select them. If you don't see
							the user you are looking for make sure they have a
							Google workspace account under your domain.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Click "Create"</StepTitle>
						<StepText>
							Once you've selected the desired user, click the
							"Create" button.
						</StepText>
					</StepSection>
				</>
			),
			image: TourGraphic4,
			buttons: (
				<NavigationButtons>
					<NavButton onClick={handleBack}>
						<NavText>← PREV</NavText>
					</NavButton>
					<NavButton onClick={handleNext}>
						<NavText>NEXT →</NavText>
					</NavButton>
				</NavigationButtons>
			),
		},
		{
			title: 'Where to find your project tools',
			description: (
				<>
					<StepText>
						Accessing your project tools and templates is simple!
						Here's how:
					</StepText>
					<StepSection>
						<StepTitle>Navigate to the Templates Page</StepTitle>
						<StepText>
							Look for the labeled "Templates" in the main
							navigation. Click it to go to the Templates page.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Explore the Tabs</StepTitle>
						<StepText>
							On the Templates page, you'll find different tabs to
							help you find the right tools.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Default Templates</StepTitle>
						<StepText>
							This tab contains pre-built, ready-to-use templates
							for common project types. Browse these to find a
							template that suits your needs.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Custom Templates</StepTitle>
						<StepText>
							If you have created your own templates or have
							templates specific to your organization, you'll find
							them here. Any templates in your ORBITO - CUSTOM
							TEMPLATES folder will appear here and be ready for
							import into Orbito.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Template Mapping</StepTitle>
						<StepText>
							This sub-tab lets you connect specific templates to
							different project types. This ensures the right
							templates are automatically available when you
							create a new project of a certain type. You can use
							this to customize which templates are suggested for
							each project type.
						</StepText>
					</StepSection>
				</>
			),
			image: TourGraphic5,
			buttons: (
				<NavigationButtons>
					<NavButton onClick={handleBack}>
						<NavText>← PREV</NavText>
					</NavButton>
					<NavButton onClick={handleNext}>
						<NavText>NEXT →</NavText>
					</NavButton>
				</NavigationButtons>
			),
		},
		{
			title: 'Creating a Project',
			description: (
				<>
					<StepText>
						This is where you'll start turning your ideas into
						actionable plans. Here's a quick guide to help you set
						up your projects efficiently.
					</StepText>
					<StepSection>
						<StepTitle>Tap the "New" Button</StepTitle>
						<StepText>
							Look for the circular "New" button with a + icon and
							tap it. This will open a menu of options.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Select "Create Project"</StepTitle>
						<StepText>
							From the menu that appears, choose "Create Project".
							This tells the system you want to create a new
							project.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Fill in the Project Details</StepTitle>
						<StepText>
							A form will pop up asking for some basic information
							about your project. Once you're ready, select
							"Review" to start building your project.
						</StepText>
					</StepSection>
					<StepSection>
						<StepTitle>Building Your Project</StepTitle>
						<StepText>
							Our system will now begin building your project
							within your Google Workspace. This might take a few
							moments. You'll see a progress indicator or message
							letting you know it's working. Don't worry, you
							don't need to do anything during this process!
						</StepText>
					</StepSection>
				</>
			),
			image: TourGraphic6,
			buttons: (
				<NavigationButtons>
					<NavButton onClick={handleBack}>
						<NavText>← PREV</NavText>
					</NavButton>
					<NavButton onClick={handleClose}>
						<NavText>COMPLETE TOUR</NavText>
					</NavButton>
				</NavigationButtons>
			),
		},
	];

	const open = openId === 'tourModal';
	const currentStep = tourSteps[activeStep];

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			sx={{ padding: 0, minWidth: '1400px', overflow: 'hidden' }}
		>
			<Container>
				<LeftContainer>
					<StepperContainer>
						<DotsStepper
							totalSteps={tourSteps.length}
							activeStep={activeStep}
							onNext={handleNext}
							onBack={handleBack}
							sx={{ maxWidth: '100%' }}
							showButtons={false}
						/>
					</StepperContainer>
					<WelcomeHeader>{currentStep.title}</WelcomeHeader>
					<ContentContainer>
						{currentStep.description}
					</ContentContainer>
					<ButtonContainer>{currentStep.buttons}</ButtonContainer>
				</LeftContainer>
				<RightContainer>
					<StepImage
						src={currentStep.image}
						alt={`Step ${activeStep + 1}`}
					/>
				</RightContainer>
			</Container>
		</Modal>
	);
}

// Styled Components
const Container = styled.div`
	height: 800px;
	display: flex;
	overflow: hidden;
`;

const LeftContainer = styled.div`
	flex: 1;
	width: 50%;
	padding: 32px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
`;

const ContentContainer = styled.div`
	flex: 1;
	margin-bottom: 32px;
`;

const StepperContainer = styled.div`
	padding: 16px 0;
`;

const RightContainer = styled.div`
	flex: 1.4;
	display: flex;
`;

const StepImage = styled.img`
	object-fit: cover;
	width: 100%;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
`;

const WelcomeHeader = styled.h2`
	font-size: 24px;
	margin-bottom: 24px;
	color: ${({ theme }) => theme.palette.text.secondary};
	font-weight: 400;
`;

const StepSection = styled.div`
	margin-bottom: 24px;
`;

const StepTitle = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.palette.text.secondary};
	font-weight: 700;
`;

const StepText = styled.p`
	font-size: 14px;
	color: ${({ theme }) => theme.palette.text.primary};
	line-height: 1.5;
	margin: 0;
	margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
`;

const NavigationButtons = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 16px;
`;

const NavButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	padding: 8px;
	display: flex;
	align-items: center;
	transition: opacity 0.2s;

	&:hover {
		opacity: 0.7;
	}
`;

const NavText = styled.span`
	color: ${({ theme }) => theme.palette.text.secondary};
	font-size: 14px;
	font-weight: 400;
`;

interface TourStep {
	title: string;
	description: React.ReactNode;
	image: string;
	buttons?: React.ReactNode;
}
