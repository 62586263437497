// authReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncStage } from '../reduxCommon';
import { User } from '../models/user';

interface AuthState {
	stage: AsyncStage;
	error: string;
	user: User;
	astroToken: string;
	googleAccessToken: string;
	googleIdToken: string;
}

const defaultUser: User = {
	id: '',
	email: '',
	firstName: '',
	lastName: '',
	role: '',
	created: '',
	updated: '',
	alwaysAddToProjects: false,
	acceptedInvite: false,
	acceptedTermsAndConditions: false,
	acceptedTermsAndConditionsDate: '',
	googleId: '',
	preferences: {
		theme: 'dark',
	},
	helpers: {
		tourTooltipViewed: false,
	},
};

const initialState: AuthState = {
	stage: AsyncStage.NoOp,
	error: '',
	user: defaultUser,
	astroToken: '',
	googleAccessToken: '',
	googleIdToken: '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		asyncCallStart: (state: AuthState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: AuthState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: AuthState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		loginSuccess: (
			state: AuthState,
			action: PayloadAction<{
				user: User;
				astroToken: string;
			}>
		) => {
			state.user = action.payload.user;
			state.astroToken = action.payload.astroToken;
			state.stage = AsyncStage.Success;
		},
		logout: (state: AuthState) => {
			state.user = defaultUser;
			state.googleAccessToken = '';
			state.googleIdToken = '';
			state.astroToken = '';
			state.stage = AsyncStage.NoOp;
		},
		updateSelfSuccess: (
			state: AuthState,
			action: PayloadAction<{
				user: User;
			}>
		) => {
			state.user = { ...state.user, ...action.payload.user };
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	loginSuccess,
	logout,
	updateSelfSuccess,
} = authSlice.actions;

export default authSlice.reducer;
