import React from 'react';
import ScreenContainer from '../common/layout/ScreenContainer';
import {
	ProjectsRecentlyCreated,
	ProjectsRecentlyArchived,
} from './ProjectsLog';
import { useSelector } from 'react-redux';
import { RootState } from '../../data/store';
import { HeaderTitle } from '../common/layout/PageHeader';
import QuickLinks from './QuickLinks';
import KeyLinks from './KeyLinks';
import { Grid, Box } from '@mui/material';

export default function HomeDashboardScreen() {
	const { user } = useSelector((state: RootState) => state.authState);

	return (
		<ScreenContainer style={{ padding: '2px', overflow: 'auto' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<HeaderTitle>
					Welcome, {user?.firstName} {user?.lastName}
				</HeaderTitle>
				<Grid container spacing={2}>
					<Grid item xs={12} md={9}>
						<QuickLinks />
					</Grid>
					<Grid item xs={12} md={3}>
						<KeyLinks />
					</Grid>
					<Grid item xs={12} md={6}>
						<ProjectsRecentlyCreated />
					</Grid>
					<Grid item xs={12} md={6}>
						<ProjectsRecentlyArchived />
					</Grid>
				</Grid>
			</Box>
		</ScreenContainer>
	);
}
