import { CompanyTemplate } from './template';
import { User } from './user';

import { DatedUserReference } from './user';

export interface Company {
	initialized: boolean;
	googleDriveSetupVerified: boolean;
	id: string;
	name: string;
	status: string;
	created: string;
	updated: string;
	quickLinks: CompanyQuickLink[];
	templates: CompanyTemplate[];
	projectTypes: ProjectType[];
	domain: string;
	quickBooksTimeConnection: QuickBooksConnection;
	subscription?: CompanySubscription;
}

export interface CompanyUsers {
	initialized: boolean;
	count: number;
	list: User[];
	filter: {
		page: number;
		pageSize: number;
		sortModel: any;
		onlyShowUnused: boolean;
		folders?: string[];
		where: object;
	};
}

export interface CompanyUpdateDTO {
	quickLinks?: CompanyQuickLink[];
	googleDriveSetupVerified?: boolean;
	googleDriveSetupVerifiedDate?: Date;
}

export interface CompanyQuickLink {
	id: string;
	title: string;
	url: string;
	icon: string;
	// order: number;
}

export interface DynamicVariable {
	name: string;
	value: string;
	description: string;
}

export interface QuickBooksConnection {
	connectedBy: DatedUserReference;
	accessToken: string;
	lastFetched: string;
	failure?: boolean;
	lastFailureReason?: string;
	lastFailureDate?: Date;
	qbInfoSheets: QbInfoSheetItem[];
}

export interface CompanySubscription {
	connectedBy: DatedUserReference;
	trialActive: boolean;
	trialStart: Date;
	trialEnd: Date;
	subscriptionType: string;
	subscriptionId: string;
	customerId: string;
	status: string;
	subscriptionStartDate: Date;
	subscriptionEndDate: Date;
}

export interface QbInfoSheetItem {
	key: String;
	value: QbInfoSheet;
}

export interface QbInfoSheet {
	googleFileId: string;
	qbProjectName: string;
	lastUpdated: Date;
	failure: boolean;
	failureReason?: string;
	sheetName: string;
}

export interface ProjectType {
	id: string;
	display: string;
	color: string;
	textColor: string;
	active: boolean;
	createdBy: DatedUserReference;
	created: string;
}

export interface CompanyUpdateProjectTypesDTO {
	id?: string;
	display?: string;
	color?: string;
	textColor?: string;
	active?: boolean;
}

export interface CompleteCompanySetupDTO {
	name: string;
	type: string;
	address: Address;
	firstName: string;
	lastName: string;
}

export interface Address {
	line1: string;
	line2: string;
	city: string;
	state: string;
	zip: string;
}

export const CompanyStatuses = {
	INCOMPLETE: {
		id: 'INCOMPLETE',
		display: 'Incomplete',
	},
	ACTIVE: {
		id: 'ACTIVE',
		display: 'Active',
	},
	INACTIVE: {
		id: 'INACTIVE',
		display: 'Inactive',
	},
};
